import React, { FunctionComponent, useEffect } from 'react';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import Container from '../Container';
import { StyledFooter, StyledHr } from '../../styles';
import { RouterLink, Link } from '../Link';
import Header from '../Header';
import { redirectLanguage } from '../../utils/language';

export interface RouteI {
  component: any;
  path: string;
}

export interface LinksI {
  text: string;
  href: string;
  routerLink?: boolean;
}

export interface FooterI {
  links: Array<LinksI>;
}

export interface HeaderI {
  links: Array<LinksI>;
}

export interface P {
  routes: Array<RouteI>;
  header: HeaderI;
  footer: FooterI;
  noHeader?: boolean;
  noFooter?: boolean;
}

const Navigation: FunctionComponent<P> = ({
  routes,
  footer,
  header,
  noHeader,
  noFooter,
}) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    window.focus();
    window.scrollTo(0, 0);
    if (!noHeader && !location.pathname.includes('oauth')) {
      redirectLanguage(history, location);
    }
  }, [location.pathname]);

  return (
    <>
      {!noHeader && <Header header={header} />}

      <Switch>
        {routes.map((route, i) => {
          return (
            <Route
              key={`navigation-${route.path}-${i}`}
              path={route.path}
              exact
              render={(props) => <route.component {...props} />}
            />
          );
        })}
      </Switch>
      {!noFooter && (
        <>
          <StyledHr isLong doubleMargin />

          <Container>
            <StyledFooter>
              {footer.links.map((link, i) => {
                if (link.routerLink) {
                  return (
                    <RouterLink
                      key={`footer.links-${link.href}-${i}`}
                      to={link.href}
                    >
                      {link.text}
                    </RouterLink>
                  );
                }

                return (
                  <Link key={`footer.links-${link.href}-${i}`} href={link.href}>
                    {link.text}
                  </Link>
                );
              })}
            </StyledFooter>
          </Container>
        </>
      )}
    </>
  );
};

export default Navigation;
