import { websiteName } from '../../utils/websiteName';
import { domainName } from '../../utils/domainName';

const descriptions = {
  de: {
    landing: `${websiteName} fügt Ihren Web-Apps Identitäts- & Zugriffsverwaltung hinzu.`,
    features: `Alle Features von ${websiteName}. Hier finden Sie alle Informationen zu unseren Angeboten.`,
    pricing: `Die Preise von ${websiteName}. Hier finden Sie alle Informationen zu unseren Angeboten.`,
    imprint: `Das Impressum von ${websiteName}. Hier finden Sie alle Informationen zum Herausgeber.`,
    terms: `Die allgemeinen Geschäftsbedingungen von ${websiteName}. Hier finden Sie alle Informationen zu unseren AGB.`,
    privacy: `Die Datenschutzerklärung von ${websiteName}. Datenschutz und Transparenz stehen bei uns an oberster Stelle.`,
  },
  en: {
    landing: `${websiteName} adds identity & access management to your web apps.`,
    features: `All features of ${websiteName}. Here are all details and information about our offers.`,
    pricing: `The pricing for ${websiteName}. Here are all details and information about our offers.`,
    imprint: `The imprint of ${websiteName}. Here are all details and information about the publisher.`,
    terms: `The terms and conditions of ${websiteName}. Here are all details and information about our T&C.`,
    privacy: `The privacy policy of ${websiteName}. Data protection and transparency are our top priorities.`,
  },
};

const titles = {
  de: {
    landing: `IAM | ${websiteName}`,
    features: `Features | ${websiteName}`,
    pricing: `Preise | ${websiteName}`,
    imprint: `Impressum | ${websiteName}`,
    terms: `AGB | ${websiteName}`,
    privacy: `Datenschutzerklärung | ${websiteName}`,
  },
  en: {
    landing: `IAM | ${websiteName}`,
    features: `Features | ${websiteName}`,
    pricing: `Pricing | ${websiteName}`,
    imprint: `Imprint | ${websiteName}`,
    terms: `Terms of Conditions | ${websiteName}`,
    privacy: `Privacy policy | ${websiteName}`,
  },
};

const images = {
  de: {
    landing: `${domainName}/img/og/og_img.png`,
    features: `${domainName}/img/og/og_img.png`,
    pricing: `${domainName}/img/og/og_img.png`,
    imprint: `${domainName}/img/og/og_img.png`,
    terms: `${domainName}/img/og/og_img.png`,
    privacy: `${domainName}/img/og/og_img.png`,
  },
  en: {
    landing: `${domainName}/img/og/og_img.png`,
    features: `${domainName}/img/og/og_img.png`,
    pricing: `${domainName}/img/og/og_img.png`,
    imprint: `${domainName}/img/og/og_img.png`,
    terms: `${domainName}/img/og/og_img.png`,
    privacy: `${domainName}/img/og/og_img.png`,
  },
};

const i18n = {
  de: {
    landing: {
      title: titles.de.landing,
      description: descriptions.de.landing,
      og: {
        title: titles.de.landing,
        site_name: titles.de.landing,
        image: images.de.landing,
        url: `${domainName}/de`,
        description: descriptions.de.landing,
      },
      twitter: {
        title: titles.de.landing,
        image: images.de.landing,
        description: descriptions.de.landing,
      },
    },
    pricing: {
      title: titles.de.pricing,
      description: descriptions.de.pricing,
      og: {
        title: titles.de.pricing,
        site_name: titles.de.pricing,
        image: images.de.pricing,
        url: `${domainName}/de/preise`,
        description: descriptions.de.pricing,
      },
      twitter: {
        title: titles.de.pricing,
        image: images.de.pricing,
        description: descriptions.de.pricing,
      },
    },
    features: {
      title: titles.de.features,
      description: descriptions.de.features,
      og: {
        title: titles.de.features,
        site_name: titles.de.features,
        image: images.de.features,
        url: `${domainName}/de/features`,
        description: descriptions.de.features,
      },
      twitter: {
        title: titles.de.features,
        image: images.de.features,
        description: descriptions.de.features,
      },
    },
    imprint: {
      title: titles.de.imprint,
      description: descriptions.de.imprint,
      og: {
        title: titles.de.imprint,
        site_name: titles.de.imprint,
        image: images.de.imprint,
        url: `${domainName}/de/impressum`,
        description: descriptions.de.imprint,
      },
      twitter: {
        title: titles.de.imprint,
        image: images.de.imprint,
        description: descriptions.de.imprint,
      },
    },
    terms: {
      title: titles.de.terms,
      description: descriptions.de.terms,
      og: {
        title: titles.de.terms,
        site_name: titles.de.terms,
        image: images.de.terms,
        url: `${domainName}/de/agb`,
        description: descriptions.de.terms,
      },
      twitter: {
        title: titles.de.terms,
        image: images.de.terms,
        description: descriptions.de.terms,
      },
    },
    privacy: {
      title: titles.de.privacy,
      description: descriptions.de.privacy,
      og: {
        title: titles.de.privacy,
        site_name: titles.de.privacy,
        image: images.de.privacy,
        url: `${domainName}/de/datenschutz`,
        description: descriptions.de.privacy,
      },
      twitter: {
        title: titles.de.privacy,
        image: images.de.privacy,
        description: descriptions.de.privacy,
      },
    },
  },
  en: {
    landing: {
      title: titles.en.landing,
      description: descriptions.en.landing,
      og: {
        title: titles.en.landing,
        site_name: titles.en.landing,
        image: images.en.landing,
        url: `${domainName}/en`,
        description: descriptions.de.landing,
      },
      twitter: {
        title: titles.de.landing,
        image: images.de.landing,
        description: descriptions.de.landing,
      },
    },
    pricing: {
      title: titles.en.pricing,
      description: descriptions.en.pricing,
      og: {
        title: titles.en.pricing,
        site_name: titles.en.pricing,
        image: images.en.pricing,
        url: `${domainName}/en/pricing`,
        description: descriptions.de.pricing,
      },
      twitter: {
        title: titles.de.pricing,
        image: images.de.pricing,
        description: descriptions.de.pricing,
      },
    },
    features: {
      title: titles.en.features,
      description: descriptions.en.features,
      og: {
        title: titles.en.features,
        site_name: titles.en.features,
        image: images.en.features,
        url: `${domainName}/en/features`,
        description: descriptions.de.features,
      },
      twitter: {
        title: titles.de.pricing,
        image: images.de.pricing,
        description: descriptions.de.pricing,
      },
    },
    imprint: {
      title: titles.en.imprint,
      description: descriptions.en.imprint,
      og: {
        title: titles.en.imprint,
        site_name: titles.en.imprint,
        image: images.en.imprint,
        url: `${domainName}/en/imprint`,
        description: descriptions.en.imprint,
      },
      twitter: {
        title: titles.en.imprint,
        image: images.en.imprint,
        description: descriptions.en.imprint,
      },
    },
    terms: {
      title: titles.en.terms,
      description: descriptions.en.terms,
      og: {
        title: titles.en.terms,
        site_name: titles.en.terms,
        image: images.en.terms,
        url: `${domainName}/en/terms`,
        description: descriptions.en.terms,
      },
      twitter: {
        title: titles.en.terms,
        image: images.en.terms,
        description: descriptions.en.terms,
      },
    },
    privacy: {
      title: titles.en.privacy,
      description: descriptions.en.privacy,
      og: {
        title: titles.en.privacy,
        site_name: titles.en.privacy,
        image: images.en.privacy,
        url: `${domainName}/en/privacy`,
        description: descriptions.en.privacy,
      },
      twitter: {
        title: titles.en.privacy,
        image: images.en.privacy,
        description: descriptions.en.privacy,
      },
    },
  },
};

export default i18n;
