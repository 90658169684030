import { darken } from 'polished';
import { styled, css } from '../../theme';

interface P {
  light?: boolean;
  ta?: string;
  mt?: string;
  sub?: boolean;
}

const BaseHeading = css<P>`
  margin-top: 2rem;

  &:after {
    content: '';
    display: block;
    border-radius: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 5px;
    width: 80px;
    background: ${({ theme }) => theme.colors.brandSecondary};
    ${({ ta }) =>
      ta &&
      css`
        margin: 10px auto;
        width: 180px;
      `}
  }
`;

const BaseColor = css<P>`
  ${({ light }) =>
    light &&
    css`
      color: white;
    `}
  ${({ sub, theme }) =>
    sub &&
    css`
      color: ${theme.colors.fontPrimaryLight};
    `}
  ${({ sub, light, theme }) =>
    sub &&
    light &&
    css`
      color: ${theme.colors.brandSecondary};
    `}
`;

const Stylez = css<P>`
  ${({ ta }) =>
    ta &&
    css`
      text-align: ${ta};
    `}
  ${({ mt }) =>
    mt &&
    css`
      margin-top: ${mt};
    `}
`;

export const StyledH1 = styled.h1`
  ${BaseHeading};
  ${BaseColor};
  ${Stylez};
`;

export const StyledH2 = styled.h2`
  ${BaseHeading};
  ${BaseColor};
  ${Stylez};
`;

export const StyledH3 = styled.h3`
  margin: 0;
  margin-top: 1rem;
  ${BaseColor};
  ${Stylez};

  + p {
    margin-top: 0.5rem;
  }
`;

export const StyledP = styled.p`
  ${BaseColor};
  ${Stylez};
`;
