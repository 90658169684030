import React from 'react';
import { render } from 'react-dom';
import { LanguageProvider, language } from '../../utils/language';

import App from './App';

render(
  <LanguageProvider language={language()}>
    <App />
  </LanguageProvider>,
  document.getElementById('main'),
);
