import { styled, css } from '../../theme';
import { rgba } from 'polished';

export const StyledAccordionHeader = styled.div<{ active?: boolean }>`
  font-weight: bold;
`;

export const Li = styled.li<{ active?: boolean; dark?: boolean }>`
  color: ${({ theme, dark }) => {
    if (dark) {
      return theme.colors.white;
    }

    return theme.colors.fontPrimary;
  }};
  padding: 0.5rem 1rem;
  margin: 0;
  &::before {
    display: none;
  }
  cursor: pointer;
  ${({ theme, active, dark }) => {
    if (active) {
      return css`
        background: ${dark ? rgba(0, 0, 0, 0.2) : rgba(0, 0, 0, 0.1)};
      `;
    }
  }};
  p > a {
  }
`;

export const Ul = styled.ul<{
  type?: string;
  open?: boolean;
}>`
  ${({ type, open }) => {
    if (type === 'dropdown' && open) {
      return css`
        height: 100%;
        width: 100%;
        opacity: 1;
      `;
    } else if (type === 'dropdown' && !open) {
      return css`
        height: 0;
        width: 100%;
        overflow: hidden;
        opacity: 0;
      `;
    }
  }}
  transition: height 0.15s ease-in, opacity 0.15s ease-in;
  padding: 0;
`;

export const ListGroup = styled.div<{ active?: boolean; dark?: boolean }>`
  border-bottom: 1px solid
    ${({ theme, dark }) =>
      dark ? `${rgba(0, 0, 0, 0.2)}` : `${rgba(0, 0, 0, 0.05)}`};
`;
