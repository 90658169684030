export const userdocksConfig = {
  authServer: {
    apiUri: `https://${process.env.SERVER_NAME_API}`,
    loginUri: `https://${process.env.SERVER_NAME_LOGIN}`,
    sdkUri: `https://${process.env.SERVER_NAME_SCRIPT}`,
  },
  app: {
    origin: `https://${process.env.SERVER_NAME_LOGIN}`,
    clientId: 'f0af4569-4d5d-4c20-af95-5a80c74e30a6',
    redirectUri: `https://${process.env.SERVER_NAME_APP}/callback`,
  },
};

export default userdocksConfig;
