import { contactEmail } from '../../utils/contactEmail';

const i18n = {
  de: {
    terms: {
      heading: 'Allgemeine Geschäftsbedingungen',
      1: {
        heading: '§1 Vertragsgegenstand',
        text: [
          'ist die Überlassung der Nutzung der Software "userdocks.com", sowie die Speicherung der Daten des Auftraggebers auf den Servern des europäischen Rechenzentrums innerhalb der Europäischen Union (EU) von "Amazon Web Services, Inc., P.O. Box, 81226 Seattle, WA 98108-1226", (Serverstandort: Frankfurt am Main, Deutschland), die als Dienstleistung in Form von "Software as a Service" (SaaS) über das Medium Internet, vom Auftragnehmer, zur Verfügung gestellt wird.',
        ],
      },
      2: {
        heading: '§2 Leistungsumfang',
        text: [
          'Art und Umfang der Leistung sind auf der Seite auf der die Preise gelistet (siehe Link nach den Absätzen) sind und in der jeweiligen Benutzerdokumentation (siehe Link nach den Absätzen) festgelegt, aber nicht darauf beschränkt, und beschreiben im Detail die Handhabung und Nutzung der Software "userdocks.com".',
          'Änderungen an den Leistungsumfang bleiben dem Auftragnehmer jederzeit vorbehalten, einschließlich, aber nicht beschränkt über die Anzahl der Nutzer pro Plan, die Anzahl der API Aufrufe, die Speicherkapazität der Daten des Auftragnehmers, und die Bandbreite der übertragenen Daten des Auftraggebers zu den Servern des Aufragnehmers und vice versa. Der Auftraggeber wird gegebenfalls rechtzeitig schriftlich darüber informiert. Sollte nichts weiteres zwischen den Vertragspartnern bestimmt sein, so gelten weiterhin alle Punkte innerhalb dieser Geschäftsbedingungen.',
        ],
        link: [
          {
            pathname: '/de/preise',
            text:
              'Lesen Sie die neueste Version unserer Preisliste mit Leistungsumfang.',
          },
          {
            extern: true,
            pathname: 'https://docs.userdocks.com/',
            text:
              'Lesen Sie die neueste Version unserer Benutzerdokumentation.',
          },
        ],
      },
      3: {
        heading: '§3 Nutzungsbedingungen',
        text: [
          '§3.1 Rechte Auftraggeber',
          'Der Auftraggeber erhält ein ausschließliches, nicht übertragbares Recht zur Nutzung der Software "userdocks.com" und der dazugehörigen Software-Dokumentation für die im Vertrag vereinbarte Dauer. Die Software wird dem Auftraggebern über das Internet, beim Übergabepunkt (Router Ausgang) des Rechenzentrums, bereitgestellt. Dem Auftraggeber ist es untersagt die Software zu kopieren, zu vervielfältigen bzw. separate Anwendungen zu erzeugen oder in einer sonstigen Form, als in der ihm auf dem Server zur Verfügung gestellten, zu nutzen.',
          '§3.2 Rechte des Auftraggebers an seinen erfassten Daten',
          'Die durch den Auftraggeber erfassten, verarbeiteten und erzeugten Daten werden auf den Servern des Rechenzentrums abgespeichert und verbleiben im alleinigen Besitz des Auftraggebers. Nach Vertragsende hat der Auftraggeber das Recht auf Herausgabe seiner Daten zu begehren. Die Übermittlung der Daten erfolgt auf elektronischem Weg und wird zeitnah an den Auftraggeber übermittelt. Die Verantwortung der erfassten, verarbeiteten und erzeugten Daten, sowie die Wahrung der Rechte (Auskunft, Verwendung, Löschung ...) der betroffenen Personen, liegt ausschließlich beim Auftraggeber.',
          '§3.3 Vertragsdauer / Kündigung',
          'Die Vertragsdauer beträgt 12 Monate (bzw. 1 Monat) und verlängert sich automatisch um weitere 12 Monate (bzw. 1 Monat) wenn nicht 14 Tage vor Ablauf der Vertragsdauer, gekündigt wird. Die offenen Beträge werden über die vom Auftraggeber zur Verfügung gestellten Kreditkartendaten eingezogen oder der Auftragnehmer stellt dem Auftraggeber eine Rechnung und der Auftraggeber überweißt den offene Nutzungsbetrag auf das vom Aufragnehmer angegebenen Konto. Der Auftragnehmer ist berechtigt bei Zahlungsverzug oder bei Verletzung der Nutzungsbedingungen durch den Auftraggeber, den Vertrag fristlos zu kündigen und behält sich das Recht vor, etwaige Schadenersatzansprüche, bei vertragswidrigem Verhalten, zu begehren.',
        ],
      },
      4: {
        heading: '§4 Wartungs- und Servicearbeiten',
        text: [
          'Der Auftraggeber wird vor möglichen Wartungs- und Servicearbeiten zeitnah informiert. In der Regel liegt die Systemverfügbarkeit der Server bei 99% im Jahresmittel. Sollte der Auftraggeber eine Störung bemerken, so ist diese umgehend an den Auftragnehmer zu melden. Vor der Störungsmeldung muss der Auftraggeber in seinem eigenen Verantwortungsbereich liegende Störungen prüfen. Störungsmeldungen werden innerhalb von 2 Stunden während der Supportzeiten bearbeitet.',
        ],
      },
      5: {
        heading: '§5 Gewährleistung',
        text: [
          'Nach heutigem Stand der Technik können Fehler in der Software unter allen Anwendungsbedingungen nicht ausgeschlossen werden. Sollten Fehler auftreten, so werden diese unentgeltlich beseitigt, vorausgesetzt der Fehler ist reproduzierbar. Es obliegt dem Auftragnehmer, ob zur Wahrung der Gewährleistung des Auftraggebers, eine Nachbesserung oder eine neue Version zur Verfügung gestellt wird, um die vertragsmäßige Nutzung der Software zu gewährleisten.',
          'Die Gewährleistung ist ausgeschlossen, wenn die Software manipuliert oder anderwärtig nicht vertragsgemäß benutzt, wird.',
        ],
      },
      6: {
        heading: '§6 Beschränkung der Haftung',
        text: [
          'Der Auftragnehmer übernimmt keine Haftung bei Verbindungsstörungen von Telekommunikationsverbindungen oder sonstigen Störungen auf Leitungswegen innerhalb des Internets, sowie bei höherer Gewalt oder durch Verschulden Dritter oder durch den Auftraggeber selbst. In keinem Fall haftet der Auftragnehmer oder seine Lieferanten für Schäden (einschließlich, jedoch nicht beschränkt auf Schäden aufgrund von Daten- oder Gewinnverlust oder Betriebsunterbrechungen), die sich aus der Nutzung oder Unfähigkeit ergeben, die Materialien auf der "userdocks.com" Website zu verwenden oder dessen API "api.userdocks.com", dessen SDK "sdk.userdocks.com", dessen Login "login.userdocks.com" oder dessen Dokumentation "docs.userdocks.com", auch wenn ein von "userdocks.com" bevollmächtigter Vertreter mündlich oder schriftlich über die Möglichkeit eines solchen Schadens informiert wurde.',
        ],
      },
      7: {
        heading: '§7 Preis und Zahlungsbedingungen',
        text: [
          'Für die Nutzung der Software userdocks im development mode (nur auf einer Maschine am localhost, nicht zugänglich über eine public IP) fallen keine Kosten an. Die Nutzung erfolgt auf einer fairen Nutzung. Der Auftragnehmer behält sich das Rech vor einen Kunden im Development Modus zu sperren wenn dieser keine faire Nutzung hat (userdocks erlaubt keine automatisierten tests in jeglicher Form.) Jegliche übersteigung von 30 Request in der Sekunde für einen längeren unbestimmten Zeitraum erfolgt eine Überprüfung der fairen Nutzng des Auftraggebers.',
          'Für die Nutzung der Software userdocks wird eine im Vertrag vereinbarte monatliche Gebühr, entweder monatlich im Voraus für einen Monat oder jährlich im Voraus für 12 Monate , in Rechnung gestellt.',
          'Die Kosten ergeben sich aus den monatlichen aktiven Nutzern und der genutzen Bandbreite Ihrer Applikation auf userdocks, vom Auftragnehmer. Jede Applikation auf userdocks wird einzeln in Rechnung gestellt.',
          'Die genauen Preise entnehmen Sie bitte unserer Preisliste (https://userdocks.com/de/preise).',
          'Abweichungen von diesem Preis müssen explizit schriftlich festgelegt werden.',
          'Wenn Sie die Stripe-Integration vom Auftragnehmer verwenden werden Ihnen nur die Gebühren von Stripe verechnet, der Auftragnehmer nimmt keine Zusätzliche Provision pro getätigter Zahlung.',
          'Änderungen an den Provisionen für getätigte Zahlungen über Stripe sind dem Auftragnehmer jederzeit vorbehalten.',
          'Änderungen am Preis sind dem Auftragnehmer jederzeit vorbehalten.',
          'Der Auftraggeber wird gegebenfalls rechtzeitig schrftlich darüber informiert. Sollte nicht weiteres zwischen den Vertragspartnern bestimmt sein, so gelten weiterhin alle Punkte innerhalb dieser Geschäftsbedingungen.',
          'Für alle Zahlungsvorgänge nutzen wir die Dienste von Stripe, Inc., 185 Berry Street, Suite 550, San Francisco, CA 94107, USA. Stripe, Inc. ist gemäß dem EU-US-Abkommen „Privacy Shield" zertifiziert. Der „Privacy Shield" ist eine Vereinbarung zwischen der Europäischen Union (EU) und den USA, um die Einhaltung der europäischen Datenschutzstandards in den USA sicherzustellen. Gegenüber dem Auftragnehmer, ist der Auftraggeber nicht berechtigt Forderungen aufzurechnen.',
        ],
        link: [
          {
            pathname: '/de/preise',
            text: 'Lesen Sie die neueste Version unserer Preisliste.',
          },
        ],
      },
      8: {
        heading: '§8 Datenschutz',
        text: [
          'Die gesetzlichen Datenschutzbestimmungen werden bei etwaigen zu verarbeitenden personenbezogener Daten eingehalten. Der Auftragnehmer verpflichtet seine Mitarbeiter, die Bestimmungen gemäß dem Datenschutzgesetz einzuhalten.',
        ],
        link: [
          {
            text:
              'Lesen Sie die neueste Version unserer Datenschutzbestimmungen',
            pathname: '/de/datenschutz',
          },
        ],
      },
      9: {
        heading: '§9 Sonstiges',
        text: [
          'Sollten einzelne Bestimmungen dieses Vertrages unwirksam sein oder unwirksam werden, so wird hierdurch der übrige Inhalt dieses Vertrages nicht berührt. Die Vertragspartner werden partnerschaftlich zusammenwirken, um eine Regelung zu finden, die den unwirksamen Bestimmungen möglichst nahekommt.',
        ],
      },
      10: {
        heading: '§10 Links',
        text: [
          'Auf der website von "userdocks.com" und desses Subdomains werden Links zu externen Seiten bereitgestellt. Der Auftragnehmer ist in keinster Weise für die Inhalte dieser Verlinkungen verantwortlich. Diese Einbettung dieser externen Links soll in keiner Weise User verleiten darauf zu klicken. Die Verwendung von externen Links obliegt in der Verantwortung des Users selbst.',
        ],
      },
      11: {
        heading: '§11 Schlussbestimmungen',
        text: [
          'Jedwede Änderungen und Ergänzungen des Vertrages bedürfen der Schriftform. Soweit nicht anders vereinbart, gelten die zwischen Unternehmern zur Anwendung kommenden gesetzlichen Bestimmungen ausschließlich nach österreichischem Recht, auch dann, wenn der Auftrag im Ausland durchgeführt wird. Für eventuelle Streitigkeiten gilt ausschließlich die örtliche Zuständigkeit des sachlich zuständigen Gerichtes für den Geschäftssitz des Auftragnehmers als vereinbart. Für den Verkauf an Verbraucher im Sinne des Konsumentenschutzgesetzes gelten die vorstehenden Bestimmungen nur insoweit, als das Konsumentenschutzgesetz nicht zwingend andere Bestimmungen vorsieht.',
          'Für den Fall von Streitigkeiten aus diesem Vertrag, die nicht einvernehmlich geregelt werden können, vereinbaren die Vertragsparteien einvernehmlich zur außergerichtlichen Beilegung des Konfliktes eingetragene Mediatoren (ZivMediatG) mit dem Schwerpunkt WirtschaftsMediation aus der Liste des Justizministeriums Österreich beizuziehen. Sollte über die Auswahl der WirtschaftsMediatoren inhaltlich kein Einvernehmen hergestellt werden können, werden frühestens ein Monat ab Scheitern der Verhandlungen rechtliche Schritte eingeleitet. Im Falle einer nicht zustande gekommenen oder abgebrochenen Mediation, gilt in einem allfällig eingeleiteten Gerichtsverfahren österreichisches Recht. Sämtliche aufgrund einer vorherigen Mediation angelaufenen notwendigen Aufwendungen, insbesondere auch jene für eine(n) beigezogene(n) RechtsberaterIn, können vereinbarungsgemäß in einem Gerichts- oder Schiedsgerichtsverfahren als „vorprozessuale Kosten“ geltend gemacht werden.',
        ],
      },
      date: {
        text: ['Stand: 19.09.2020'],
      },
    },
  },
  en: {
    terms: {
      heading: 'General Terms and Conditions of Use',
      1: {
        heading: '§1 subject matter of the contract',
        text: [
          'The subject of the contract is the transfer of the use of the software "userdocks.com", as well as the storage of the data of the on the servers of the European computer center of "Amazon Web Services, Inc., P.O. Box, 81226 Seattle, WA 98108-1226", (Serverlocation: Frankfurt am Main, Germany), which serves as a service in the form of "Software as a Service" (SaaS) is provided via the Internet medium, by the Contractor.',
        ],
      },
      2: {
        heading: '§2 Scope of services',
        text: [
          'The type and scope of the service is defined on the page with the price list (see link after the paragraphs) and in the respective user documentation (see link after the paragraphs), but is not limited to these documents, and describe in detail the handling and use of the software "userdocks.com".',
          "The contractor reserves the right to make changes to the scope of services at any time, including but not limited to the number of users per plan, the number of API calls, the storage capacity of the contractor's data, and the bandwidth of the data transmitted by the client to the servers of the contractor and vice versa. The client will be informed about this in good time. If nothing else is determined between the contractual partners, all points within these terms and conditions continue to apply.",
        ],
        link: [
          {
            pathname: '/en/pricing',
            text:
              'Read the latest version of our price list and scope of services.',
          },
          {
            extern: true,
            pathname: 'https://docs.userdocks.com/',
            text: 'Read the latest version of our user documentation.',
          },
        ],
      },
      3: {
        heading: '§3 Terms of use',
        text: [
          '§3.1 Client rights',
          'The client receives an exclusive, non-transferable right to use the software "userdocks.com" and the associated software documentation for the duration agreed in the contract. The software is provided to the client via the Internet, at the transfer point (Router Output) of the data center. The client is prohibited from copying, duplicating or copying the software. To separate applications or in any form other than the one provided to them on the server.',
          '§3.2 Rights of the client to its collected data',
          'The data collected, processed and generated by the Client shall be stored on the servers of the computer center and shall remain the sole property of the Client. After the end of the contract, the client has the right to request the handover of its data. The data shall be transmitted electronically and shall be transmitted promptly to the client. The responsibility of the collected, processed and generated data, as well as the protection of the rights (information, use, deletion ...) of the data subjects, lies exclusively with the client.',
          '3.3 Contract duration / termination',
          'The contract period is 12 months (or 1 Month) and is automatically extended by a further 12 months (or 1 Month) if not cancelled 14 days before the end of the contract period. The outstanding amounts are collected via the credit card data provided by the client or the contractor will send an invoice to the client and the client has to pay the outstanding amounts via bank transfer to the bank account of the contractor. The contractor shall be entitled to terminate the contract without notice in the case of default in payment or violation of the terms of use by the client and reserves the right to demand any claims for damages in the event of breach of contract.',
        ],
      },
      4: {
        heading: '§4 Maintenance and service work',
        text: [
          'The client is informed promptly of possible maintenance and service work. In general, server availability is 99% annual average. Should the client notice a disturbance, this must be reported immediately to the contractor. Before the fault report, the client must check faults within his own area of responsibility. Fault reports are processed within 2 hours during support hours.',
        ],
      },
      5: {
        heading: '§5 Warranty',
        text: [
          'According to the present state of the art, errors in the software cannot be excluded under all conditions of use. If errors occur, they will be removed free of charge, provided the error is reproducible. It is incumbent on the contractor to ensure that the client is satisfied with the warranty, to provide an improvement or a new version in order to ensure that the Software is used in accordance with the contract.',
          'The warranty is excluded if the software is manipulated or otherwise not used in accordance with the contract.',
        ],
      },
      6: {
        heading: '§6 Limitation of liability',
        text: [
          'The contractor does not assume any liability for connection disturbances of telecommunication connections or other disturbances on line paths within the Internet, and in cases of force majeure or by fault of third parties or by the contracting entity itself. In no case shall the Contractor or its suppliers be liable for damages (including, but not limited to, damages resulting from loss of data or profit or interruption of operation) resulting from use or inability; to use the materials on the "userdocks.com" website, or on the api "api.userdocks.com", or on the "sdk.userdocks.com", or the login "login.userdocks.com", or on the documentation "docs.userdocks.com", even if a representative authorized by "userdocks.com" has been informed orally or in writing about the possibility of such damage.',
        ],
      },
      7: {
        heading: '§7 Price and terms of payment',
        text: [
          'There are no costs for using the software userdocks in development mode (only on one machine on the localhost, not accessible via a public IP). The usage is based on a fair usage. The contractor reserves the right to block a customer in development mode if he does not have fair use (userdocks does not allow automated tests in any form) of the client. Any exceedance of 30 requests per second for a longer, indefinite period of time is followed by a review of the fair use of the client.',
          'For the use of the software, a monthly fee agreed in the contract, either monthly in advance for 1 month or yearly in advance for 12 months, will be charged.',
          'The costs result from the monthly active users and the bandwidth used by your application on userdocks by the contractor. Each application on userdocks is billed individually.',
          'The exact prices can be found in our price list (https://userdocks.com/en/pricing).',
          "If you use the Stripe integration from the contractor, you will only be charged Stripe's fees, the contractor does not take any additional commission per payment made.",
          'The contractor reserves the right to make changes to the commissions for payments made via Stripe at any time.',
          'The contractor reserves the right to make changes to the price at any time.',
          'If necessary, the client will be informed about changes in time in a written form. If nothing else is determined between the contractual partners, all points within this terms and conditions continue to apply.',
          'For all payment transactions, we use the services of Stripe, Inc., 185 Berry Street, Suite 550, San Francisco, CA 94107, USA. is certified according to the EU-US “Privacy Shield” agreement. The “Privacy Shield” is an agreement between the European Union (EU) and the United States to ensure compliance with European data protection standards in the United States. Against the Contractor, the Customer is not entitled to account claims.',
        ],
        link: [
          {
            pathname: '/en/pricing',
            text: 'Read the latest version of our price list.',
          },
        ],
      },
      8: {
        heading: '§8 Data Protection',
        text: [
          'The legal data protection regulations are observed for any personal data to be processed. The Contractor shall oblige its employees to comply with the provisions of the Data Protection Act.',
        ],
        link: [
          {
            text: 'Read latest version of our Privacy Policy',
            pathname: '/en/privacy',
          },
        ],
      },
      9: {
        heading: '§9 Miscellaneous',
        text: [
          'Should individual provisions of this contract be or become invalid, this shall not affect the remaining content of this contract. The contracting parties shall cooperate in a spirit of partnership in order to find a regulation that comes as close as possible to the ineffective provisions.',
        ],
      },
      10: {
        heading: '§10 Links',
        text: [
          'On the website of "userdocks.com" and all its subdomains links to external sites are provided. The contractor is in no way responsible for the content of these links. This embedding of these external links shall in no way induce users to click on them. The use of external links is the responsibility of the user.',
        ],
      },
      11: {
        heading: '§11 Final Provisions',
        text: [
          'Any amendments or additions to the contract shall be in writing. Unless otherwise agreed, the legal provisions applicable between contractors shall apply exclusively in accordance with Austrian law, even if the order is carried out abroad. In the event of any disputes, only the local jurisdiction of the competent court shall be deemed to be agreed for the place of business of the contractor. For sales to consumers within the meaning of the Consumer Protection Act, the above provisions apply only to the extent that the Consumer Protection Act does not necessarily provide for other provisions.',
          'In the event of a dispute arising out of this Treaty that cannot be resolved by mutual agreement, the Parties shall agree by mutual agreement on the out-of-court settlement of the dispute between registered mediators (Zivmediatg) with a focus on economic mediation from the list of the Ministry of Justice Austria. If no agreement can be reached on the content of the selection of economic mediators, legal action will not be taken until at least one month after the failure of the negotiations. In the case of a mediation which has not taken place or which has been terminated, Austrian law shall apply in any legal proceedings initiated. All necessary expenses incurred as a result of a prior mediation, in particular those incurred for a legal adviser(s), may be contractually incurred in a court or arbitration as “pre-trial costs”',
        ],
      },
      date: {
        text: ['Updated: 19.09.2020'],
      },
    },
  },
};

export default i18n;
