"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getI18n = void 0;

var _ = require("../");

var getI18n = function getI18n(data, lang, fallback) {
  var fallb = data[fallback] || data[fallback.split("-")[0]] || {};
  var lng = data[lang] || data[lang.split("-")[0]] || {};
  var fullLang = (0, _.deepMerge)(fallb, lng);
  return fullLang;
};

exports.getI18n = getI18n;