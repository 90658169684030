"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLanguage = void 0;

var _browserLanguage = require("../browserLanguage");

var getLanguage = function getLanguage(options, props) {
  if (props && props.lang) {
    return props.lang;
  }

  return options.lang || _browserLanguage.browserLanguage;
};

exports.getLanguage = getLanguage;