import language, { splittedLocationPathname, hasLanguage } from './getLanguage';

const hasLang = (location: any) => {
  const lang = language();
  const path = hasLanguage
    ? location.pathname
    : splittedLocationPathname.filter((_: any, i: number) => i !== 0);

  return {
    hasLanguage,
    lang,
    path,
  };
};

export default hasLang;
