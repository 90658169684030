import baseStyled, {
  css as baseCss,
  keyframes as baseKeyframes,
  ThemedStyledInterface,
  ThemedCssFunction,
} from 'styled-components';
import { lighten, darken } from 'polished';

export const deviceSize = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1124px',
  laptopL: '1440px',
  desktop: '2560px',
};

const baseColors = {
  transparent: 'transparent',
  blue: '#14253E',
  red: '#EE5557',
  // green: '#006428',
  green: '#50a14f',
  orange: '#FF8000',
  orange2: '#ffbf00',
  lightBlue: '#00FFF9',
  white: '#FFFFFF',
  lightgray: 'rgba(0, 0, 0, .2)',
  gray: 'rgba(0, 0, 0, .6)',
  black: '#3C464B',
  // black: '#000000',
  github: '#24292E',
  gitlab: '#000000',
  bitbucket: '#000000',
  google: '#4285F4',
  facebook: '#3b5998',
  twitter: '#1DA1F2',
};

export const device = {
  mobileS: `(min-width: ${deviceSize.mobileS})`,
  mobileM: `(min-width: ${deviceSize.mobileM})`,
  mobileL: `(min-width: ${deviceSize.mobileL})`,
  tablet: `(min-width: ${deviceSize.tablet})`,
  laptop: `(min-width: ${deviceSize.laptop})`,
  laptopL: `(min-width: ${deviceSize.laptopL})`,
  desktop: `(min-width: ${deviceSize.desktop})`,
  desktopL: `(min-width: ${deviceSize.desktop})`,
};

export const theme = {
  metrics: {
    margin: '1rem',
    bigMargin: '2rem',
    maxWidth: '1024px',
    maxWidthMedium: '890px',
    maxWidthSlim: '690px',
    headerHeight: '60px',
    borderRadius: '6px',
  },
  colors: {
    ...baseColors,
    brandPrimary: baseColors.blue,
    brandPrimaryDark: darken(0.1, baseColors.blue),
    brandPrimaryLight: lighten(0.1, baseColors.blue),
    brandSecondary: baseColors.green,
    brandSecondaryDark: darken(0.1, baseColors.green),
    brandSecondaryLight: lighten(0.1, baseColors.green),
    fontPrimary: baseColors.black,
    fontPrimaryLight: lighten(0.1, baseColors.black),
    fontSecondary: baseColors.white,
    fontSecondaryDark: darken(0.1, baseColors.white),
  },
  snippets: {
    boxShadow: (bs) => baseCss`
      box-shadow: ${bs || '0px 1px 3px rgba(0, 0, 0, 0.1)'};
    `,
    boxShadowHover: baseCss`
      transition: box-shadow .3s;

      &:hover, &:focus {
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
      }
    `,
    borderRadius: (br) => baseCss`
      border-radius: ${br || '6px'};
    `,
  },
};

export type Theme = typeof theme;
export const styled = baseStyled as ThemedStyledInterface<Theme>;
export const css = baseCss as ThemedCssFunction<Theme>;
export const keyframes = baseKeyframes;
