// @ts-nocheck
import styledNormalize from 'styled-normalize';
import { createGlobalStyle } from 'styled-components';

import { theme } from './theme';

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
  font-display: swap;
  * {
    font-family: 'Open Sans', sans-serif;
  }
  *, *::after, *:before {
    box-sizing: border-box;
  }
  html {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
  }
  body {
    font-family: 'Open Sans', sans-serif;
    color: ${theme.colors.fontPrimary};
    height: 100%;
    line-height: 1.4;
  }
  #main {
    height: 100%;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Source Sans Pro', sans-serif;
  }
  form {
    white-space: nowrap;
  }
  ul {
    font-family: 'Open Sans', sans-serif;
    list-style: none;
    padding-left: 1rem;
    li {
      font-family: 'Open Sans', sans-serif;
      position: relative;
      margin: 0.5rem 0;
      line-height: 26px;
      &:before {
        content: "";
        width: 8px;
        height: 8px;
        position: absolute;
        top: 6px;
        transform: translateX(-180%);
        border-radius: 50%;
        background: ${theme.colors.brandPrimary};
      }
    }
  }
  p {
    line-height: 26px;
  }
  .react-toast-notifications__container {
    white-space: pre-line;
    height: 100%;
  }
`;

const GlobalAppStyle = createGlobalStyle`
  ${styledNormalize};
  font-display: swap;
  * {
    font-family: 'Open Sans', sans-serif;
  }
  *, *::after, *:before {
    box-sizing: border-box;
  }
  html {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
  }
  body {
    font-family: 'Open Sans', sans-serif;
    color: ${theme.colors.fontPrimary};
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Source Sans Pro', sans-serif;
  }
  form {
    white-space: nowrap;
  }
  body {
    height: 100%;
    max-width: 100%;
  }

  ul {
    font-family: 'Open Sans', sans-serif;
    list-style: none;
    padding-left: 1rem;
    li {
      font-family: 'Open Sans', sans-serif;
      position: relative;
      margin: 0.5rem 0;
      line-height: 1.4;
      &:before {
        content: "";
        width: 8px;
        height: 8px;
        position: absolute;
        top: 6px;
        transform: translateX(-180%);
        border-radius: 50%;
        background: ${theme.colors.brandPrimary};
      }
    }
  }
  p {
    line-height: 1.4;
  }
`;

export default {
  GlobalStyle,
  GlobalAppStyle,
};
