import { Link } from 'react-router-dom';

import { styled, css, device } from './theme';

export const StyledLandingPage = styled.div`
  margin-top: calc(60px + 1rem);
`;

export const StyledImage = styled.img<{ hero?: boolean; width?: string }>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  max-width: 80%;
`;

export const StyledHeader = styled.div`
  height: 80vh;
  color: ${({ theme }) => theme.colors.fontPrimary};
  position: relative;
`;

export const StyledHr = styled.div<{
  isLong?: boolean;
  doubleMargin?: boolean;
}>`
  margin: ${({ doubleMargin }) => (doubleMargin ? 2 : 1.5)}rem auto;
  height: 5px;
  width: ${({ isLong }) => (isLong ? '150px' : '100px')};
  background: ${({ theme }) => theme.colors.brandPrimary};
`;

export const StyledH1 = styled.h1`
  margin-top: 2rem;
  &:after {
    content: '';
    display: block;
    border-radius: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 5px;
    width: 80px;
    background: ${({ theme }) => theme.colors.brandPrimary};
  }
`;

export const StyledH2 = styled.h2`
  margin-top: 2rem;
  &:after {
    content: '';
    display: block;
    border-radius: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 5px;
    width: 50px;
    background: ${({ theme }) => theme.colors.brandPrimary};
  }
`;

export const StyledH3 = styled.h3`
  margin: 0;
  margin-top: 1rem;
  + p {
    margin-top: 0.5rem;
  }
`;

export const StyledSection = styled.div<{ reverse?: boolean }>`
  background: ${({ theme }) => theme.colors.brandPrimary};
  color: ${({ theme }) => theme.colors.fontSecondary};
  ${({ reverse }) =>
    !reverse &&
    css`
      clip-path: polygon(0 0, 100% 4vw, 100% 100%, 0 100%);
    `}
  ${({ reverse }) =>
    reverse &&
    css`
      clip-path: polygon(0 0, 100% 2vw, 100% calc(100% - 2vw), 0 100%);
    `}
  margin-top: 3rem;
  margin-bottom: 3rem;
  ${StyledH2} {
    &:after {
      background: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const StyledFlexGrid = styled.div<{ noc?: number }>`
  display: block;
  @media ${device.tablet} {
    display: flex;
    flex-wrap: wrap;
    ${({ noc }) =>
      noc === 2 &&
      css`
        & > div:nth-child(odd) {
          margin-right: 0.5rem;
        }
        & > div:nth-child(even) {
          margin-left: 0.5rem;
        }
      `}
  }
`;

export const StyledFlexGridCol = styled.div<{ noc?: number }>`
  max-width: 100%;
  margin: ${({ noc }) => {
    if (noc === 2) {
      return 'unset';
    }

    return '1rem';
  }};
  margin-bottom: 1rem;
  @media ${device.tablet} {
    ${({ noc }) => {
      if (noc === 4) {
        return css`
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: calc(25% - 2rem);
          max-width: calc(25% - 2rem);
        `;
      }
      if (noc === 3) {
        return css`
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: calc(33.333332% - 2rem);
          max-width: calc(33.333332% - 2rem);
        `;
      }
      if (noc === 2) {
        return css`
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: calc(50% - 0.5rem);
          max-width: calc(50% - 0.5rem);
        `;
      }
    }}
  }
`;

export const StyledRow = styled.div<{ reverse?: boolean; imgHeight?: string }>`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  padding-bottom: 2rem;
  > div {
    flex: 1;
  }
  @media ${device.tablet} {
    padding-bottom: 0;
  }
  ${({ reverse }) =>
    !reverse &&
    css`
      @media ${device.tablet} {
        flex-direction: row;
      }
    `}
  ${({ reverse }) =>
    reverse &&
    css`
      @media ${device.tablet} {
        flex-direction: row-reverse;
      }
    `}
  img {
    max-width: calc(100vw - 2rem);
    height: ${({ imgHeight }) => imgHeight || ''};
    object-fit: cover;
    @media ${device.tablet} {
      width: 100%;
    }
  }
`;

export const StyledSideLogin = styled.div`
  clip-path: polygon(0 15%, 100% 0, 96% 93%, 0 86%);
  background: white;
  padding: 2rem;
  padding-bottom: 3rem;
  position: relative;
`;

export const StyledBenefits = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const StyledBenefit = styled.div`
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  @media ${device.tablet} {
    flex-basis: 30%;
    margin-bottom: 0;
  }
  img {
    width: 30%;
    @media ${device.tablet} {
      align-self: center;
    }
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  margin: 2rem;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  a {
    margin: 0 1rem;
    margin-bottom: 0.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media ${device.tablet} {
    flex-direction: row;
    a {
      margin-bottom: 0;
    }
  }
`;

export const StyledList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media ${device.tablet} {
    flex-direction: row;
  }
  li {
    flex-basis: 50%;
    padding-right: 2rem;
  }
`;

export const StyledDiv = styled.div<{ dark?: boolean }>`
  padding: 4rem 0;
  background-color: ${({ dark, theme }) => dark && theme.colors.brandPrimary};
`;

export const StyledFlex = styled.div<{
  width?: string;
  height?: string;
  p?: string;
  jc?: string;
  ta?: string;
  ai?: string;
  fd?: string;
  mobileFd?: string;
  mr?: string;
  ml?: string;
  mt?: string;
  mb?: string;
  display?: string;
  bm?: boolean;
  flex?: number;
  fw?: string;
  dark?: boolean;
}>`
  display: ${({ display }) => display || 'flex'};
  flex-direction: ${({ fd }) => fd || 'row'};
  margin-right: ${({ mr }) => mr || '0'};
  margin-left: ${({ ml }) => ml || '0'};
  margin-top: ${({ mt }) => mt || '0'};
  margin-bottom: ${({ mb }) => mb || '0'};
  padding: ${({ p }) => p};
  flex-wrap: ${({ fw }) => fw || 'nowrap'};
  width: ${({ width }) => (width ? '100%' : '')};
  height: ${({ height }) => height};
  justify-content: ${({ jc }) => jc || 'center'};
  align-content: center;
  text-align: ${({ ta }) => ta};
  align-items: ${({ ai }) => ai || 'center'};
  flex: ${({ flex }) => flex};
  background-color: ${({ dark, theme }) => dark && theme.colors.brandPrimary};
  ${({ bm }) =>
    bm &&
    css`
      align-items: center;
    `}
  ${({ bm, fd, ai }) => {
    if (bm) {
      return css`
        @media ${device.tablet} {
          flex-direction: ${fd ? (fd === 'row' ? 'column' : 'row') : 'column'};
          align-items: ${ai || 'center'};
        }
      `;
    }
  }}
  @media ${device.tablet} {
    width: ${({ width }) => width};
    height: ${({ height }) => height};
  }
`;

export const Styled100 = styled.div`
  max-width: 100vw;
  width: 100vw;
  max-height: 100vh;
  height: 100vh;
`;

export const StyledCard = styled.div<{
  width?: string;
  mb?: string;
  flex?: number;
  height?: string;
  borderRadius?: string;
  boxShadow?: string;
}>`
  ${({ theme, boxShadow }) => theme.snippets.boxShadow(boxShadow)};
  ${({ theme, borderRadius }) => theme.snippets.borderRadius(borderRadius)};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin-bottom: ${({ mb }) => mb};
  background: ${({ theme }) => theme.colors.white};
  flex: ${({ flex }) => (flex ? `${flex} !important` : 'unset')};
`;

export const StyledCardHeader = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 1rem;
`;

export const StyledCardBody = styled.div`
  padding: 1rem;
`;

export const StyledWrapper = styled.div<{ mb?: string; width?: string }>`
  width: 100%;
  ${({ mb }) =>
    mb &&
    css`
      margin-bottom: ${mb};
    `}
  @media ${device.tablet} {
    width: ${({ width }) => width};
  }
`;

export const StyledImgWrapper = styled.div<{
  width?: string;
  noColor?: boolean;
  borderRadius?: string;
}>`
  ${({ theme, borderRadius }) => theme.snippets.borderRadius(borderRadius)}
  padding: 3rem;
  background-color: ${({ theme, noColor }) =>
    noColor ? 'unset' : theme.colors.brandSecondary};
  & > img {
    width: 100vw;
    padding: 0 2rem;
  }
  @media ${device.tablet} {
    & > img {
      width: ${({ width }) => width || '100%'};
    }
  }
`;
