import React, { FunctionComponent } from 'react';
import { ThemeProvider } from 'styled-components';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { useI18n } from 'react-i18n-kit';

import AuthProvider from '../../components/AuthProvider';
import { theme } from '../../theme';
import Helmet from '../../components/Helmet';
import GlobalStyles from '../../GlobalStyles';
import Navigation from '../../components/Navigation';
import routes from './config/routes';
import client from './config/apollo';
import footer from './config/content/footer';
import header from './config/content/header';
import { useLanguage } from '../../utils/language';
import { ApolloProvider } from '@apollo/client';

const data = {
  de: {
    footer: footer.de,
    header: header.de,
  },
  en: {
    footer: footer.en,
    header: header.en,
  },
};

const App: FunctionComponent = () => {
  const { language } = useLanguage();

  const { i18n } = useI18n(data, {
    lang: language,
  });

  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <HelmetProvider>
            <BrowserRouter>
              <GlobalStyles.GlobalStyle />
              <Navigation
                routes={routes}
                footer={i18n.footer}
                header={i18n.header}
              />
            </BrowserRouter>
          </HelmetProvider>
        </ThemeProvider>
      </AuthProvider>
    </ApolloProvider>
  );
};

export default App;
