const helmet = {
  de: {
    landing: {},
  },
  en: {
    landing: {},
  },
};

export default helmet;
