import React, { FunctionComponent, useState, useEffect } from 'react';
import { MdArrowDropDown, MdArrowDropUp, MdWork, MdAdd } from 'react-icons/md';

import { Ul, Li, ListGroup, StyledAccordionHeader } from './StyledAccordion';
import { StyledFlex } from '../../styles';

type TList = {
  key: string;
  title: string | JSX.Element;
  text: string | JSX.Element;
};

interface IOptions {
  openItem: string;
  alwaysOpen?: boolean;
}

interface IProps {
  title?: string;
  list: TList[];
  options: IOptions;
}

const replaceOldWithNewAccordionSetting = (accordion, newItem, options) => {
  return accordion.map((item) => {
    const itemName = item ? item.key : '';
    const newItemName = newItem ? newItem.key : '';

    if (itemName === newItemName) {
      return newItem;
    }

    return {
      [itemName]: {
        ...item,
        open: options?.alwaysOpen ? item.open : false,
      },
    };
  });
};

const Accordion: FunctionComponent<IProps> = (props) => {
  const { title, list, options } = props;

  const accordionSettings: any[] = [];
  const accordionList: any[] = [];

  list.forEach((item) => {
    const itemName = item ? item.key : '';

    accordionSettings.push({
      [itemName]: {
        open: options?.openItem === itemName,
      },
    });
    accordionList.push({
      [itemName]: {
        ...item,
      },
    });
  });

  const [accordion, setAccordion] = useState(accordionSettings);

  return (
    <>
      {title ? (
        <StyledFlex>
          <h2>{title}</h2>
        </StyledFlex>
      ) : null}
      {Array.isArray(accordionList) && accordionList.length > 0 ? (
        <Ul>
          {accordionList.map((item, idx) => {
            const itemName = item ? Object.keys(item)[0] : '';
            const currentAccordionItemSettings = accordion.find((i) => {
              const accordionName = i ? Object.keys(i)[0] : '';

              return itemName === accordionName;
            });

            return (
              <ListGroup
                active={currentAccordionItemSettings?.[itemName]?.open}
                key={`accordion-key-${itemName}-${idx}`}
              >
                <Li
                  tabIndex={0}
                  active={currentAccordionItemSettings?.[itemName]?.open}
                  onClick={async () => {
                    const newItem = {
                      [itemName]: {
                        open: !currentAccordionItemSettings?.[itemName]?.open,
                      },
                    };
                    const newMergedAccordionSettings = replaceOldWithNewAccordionSetting(
                      accordion,
                      newItem,
                      options,
                    );
                    await setAccordion(newMergedAccordionSettings);
                  }}
                  onKeyDown={async (e) => {
                    if (e.key === 'Enter') {
                      const newItem = {
                        [itemName]: {
                          open: !currentAccordionItemSettings?.[itemName]?.open,
                        },
                      };
                      const newMergedAccordionSettings = replaceOldWithNewAccordionSetting(
                        accordion,
                        newItem,
                        options,
                      );

                      await setAccordion(newMergedAccordionSettings);
                    }
                  }}
                >
                  <StyledFlex jc="space-between">
                    <StyledAccordionHeader
                      active={currentAccordionItemSettings?.[itemName]?.open}
                    >
                      {item?.[itemName]?.title}
                    </StyledAccordionHeader>
                    <StyledFlex ai="center">
                      {currentAccordionItemSettings?.[itemName]?.open ? (
                        <MdArrowDropUp size={28} />
                      ) : (
                        <MdArrowDropDown size={28} />
                      )}
                    </StyledFlex>
                  </StyledFlex>
                </Li>
                <Ul
                  type="dropdown"
                  open={currentAccordionItemSettings?.[itemName]?.open}
                >
                  <Li>
                    <p>{item?.[itemName]?.text}</p>
                  </Li>
                </Ul>
              </ListGroup>
            );
          })}
        </Ul>
      ) : null}
    </>
  );
};

export default Accordion;
