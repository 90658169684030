import { contactEmail } from '../../../../utils/contactEmail';

const footer = {
  de: {
    links: [
      {
        text: 'Impressum',
        href: '/de/impressum',
        routerLink: true,
      },
      {
        text: 'AGB',
        href: '/de/agb',
        routerLink: true,
      },
      {
        text: 'Datenschutz',
        href: '/de/datenschutz',
        routerLink: true,
      },
      {
        text: 'Hilfe',
        href: '/de/hilfe',
        routerLink: true,
      },
      {
        text: 'Kontakt',
        href: `mailto:${contactEmail}`,
      },
    ],
  },
  en: {
    links: [
      {
        text: 'Imprint',
        href: '/en/imprint',
        routerLink: true,
      },
      {
        text: 'Terms',
        href: '/en/terms',
        routerLink: true,
      },
      {
        text: 'Privacy Policy',
        href: '/en/privacy',
        routerLink: true,
      },
      {
        text: 'Help Center',
        href: '/en/help-center',
        routerLink: true,
      },
      {
        text: 'Contact',
        href: `mailto:${contactEmail}`,
      },
    ],
  },
};

export default footer;
