import React, { FunctionComponent, ReactComponentElement } from 'react';
import { Link } from 'react-router-dom';
import { backgrounds, darken, lighten } from 'polished';

import { styled, css, device } from '../../theme';

const baseCSS = css<{
  title?: string;
  danger?: boolean;
  outline?: boolean;
  light?: boolean;
  outlineLight?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  background?: string;
  color?: string;
  mb?: string;
  ml?: string;
  mr?: string;
  display?: string;
  jc?: string;
  ai?: string;
  mh?: string;
  pl?: string;
  icon?: boolean;
  cta?: boolean;
  ctaOutlineLight?: boolean;
  ctaOutline?: boolean;
  width?: string;
  height?: string;
  borderRadius?: string;
}>`
  color: ${({
    outlineLight,
    light,
    outline,
    background,
    theme,
    color,
    ctaOutline,
  }) => {
    if (outline || light) {
      return background || theme.colors.brandPrimary;
    }
    if (outlineLight) {
      return theme.colors.white;
    }
    if (ctaOutline) {
      return theme.colors.brandSecondary;
    }

    return color || theme.colors.fontSecondary;
  }};
  border: 0;
  padding: ${({ cta, ctaOutline, ctaOutlineLight }) =>
    cta || ctaOutline || ctaOutlineLight ? '1rem' : '0.5rem 1rem'};
  text-decoration: none;
  ${({ icon }) =>
    icon &&
    css`
      display: flex;
      justify-content: start;
      align-items: center;
    `};
  background: ${({
    light,
    outline,
    danger,
    theme,
    background,
    cta,
    ctaOutline,
    ctaOutlineLight,
  }) => {
    if (light) {
      return theme.colors.white;
    }
    if (outline || ctaOutlineLight || ctaOutline) {
      return theme.colors.transparent;
    }
    if (danger) {
      return theme.colors.brandSecondary;
    }
    if (cta) {
      return theme.colors.brandSecondary;
    }

    return background || theme.colors.brandPrimary;
  }};
  border: 1px solid
    ${({
      outlineLight,
      danger,
      theme,
      background,
      cta,
      ctaOutline,
      ctaOutlineLight,
    }) => {
      if (outlineLight || ctaOutlineLight) {
        return theme.colors.white;
      }
      if (danger) {
        return theme.colors.brandSecondary;
      }
      if (danger) {
        return theme.colors.brandSecondary;
      }
      if (cta || ctaOutline) {
        return theme.colors.brandSecondary;
      }

      return background || theme.colors.brandPrimary;
    }};
  ${({ theme, borderRadius }) => theme.snippets.borderRadius(borderRadius)};
  cursor: pointer;
  transition: background 0.3s;
  margin-bottom: ${({ mb }) => mb};
  margin-left: ${({ ml }) => ml || '0'};
  margin-right: ${({ mr }) => mr || '0'};
  display: ${({ display }) => display || ''};
  justify-content: ${({ jc }) => jc || ''};
  align-items: ${({ ai }) => ai || ''};
  white-space: nowrap;
  font-family: 'Open Sans', sans-serif;
  max-width: 100%;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  @media ${device.tablet} {
    max-width: 300px;
  }
  ${({ cta, ctaOutline, ctaOutlineLight }) =>
    (cta || ctaOutline || ctaOutlineLight) &&
    css`
      font-weight: 700;
      text-transform: uppercase;
      width: 250px;
    `}
  ${({ disabled, theme, background }) =>
    disabled &&
    css`
      cursor: not-allowed;
      background: ${background
        ? lighten(0.1, background)
        : theme.colors.brandPrimaryLight};
      &:hover,
      tive &:active {
        background: ${background
          ? lighten(0.1, background)
          : theme.colors.brandPrimaryLight};
      }
    `}
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  &:hover,
  &:active {
    background: ${({
      outlineLight,
      light,
      disabled,
      danger,
      theme,
      background,
      cta,
      ctaOutlineLight,
      ctaOutline,
    }) => {
      if (outlineLight || ctaOutlineLight) {
        return theme.colors.white;
      }
      if (light) {
        return theme.colors.brandPrimary;
      }
      if (disabled) {
        return background
          ? lighten(0.1, background)
          : theme.colors.brandPrimaryLight;
      }
      if (ctaOutline) {
        return theme.colors.brandSecondary;
      }
      if (cta) {
        return theme.colors.brandSecondaryDark;
      }
      if (danger) {
        return theme.colors.brandSecondaryDark;
      }

      return background
        ? lighten(0.1, background)
        : theme.colors.brandPrimaryLight;
    }};
    color: ${({
      outlineLight,
      theme,
      light,
      cta,
      ctaOutline,
      ctaOutlineLight,
    }) => {
      if (light || ctaOutline) {
        return theme.colors.white;
      }
      if (outlineLight) {
        return theme.colors.brandPrimary;
      }

      return theme.colors.fontSecondary;
    }};
    border: ${({ outlineLight, light, theme, ctaOutline, ctaOutlineLight }) => {
      if (light) {
        return `1px solid ${theme.colors.white}`;
      }
      if (outlineLight || ctaOutlineLight) {
        return `1px solid ${theme.colors.brandPrimary}`;
      }
      if (ctaOutline) {
        return `1px solid ${theme.colors.brandSecondary}`;
      }

      return;
    }};
  }
`;

const Button = styled.button<{
  title?: string;
  danger?: boolean;
  outline?: boolean;
  outlineLight?: boolean;
  light?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  background?: string;
  color?: string;
  mb?: string;
  ml?: string;
  mr?: string;
  display?: string;
  jc?: string;
  ai?: string;
  mh?: string;
  pl?: string;
  icon?: boolean;
  cta?: boolean;
  ctaOutlineLight?: boolean;
  ctaOutline?: boolean;
  width?: string;
  height?: string;
  borderRadius?: string;
}>`
  ${baseCSS}
`;

const RouterLinkButton = styled(Link)<{
  title?: string;
  danger?: boolean;
  outline?: boolean;
  outlineLight?: boolean;
  light?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  background?: string;
  color?: string;
  mb?: string;
  ml?: string;
  mr?: string;
  display?: string;
  jc?: string;
  ai?: string;
  mh?: string;
  pl?: string;
  icon?: boolean;
  cta?: boolean;
  ctaOutlineLight?: boolean;
  ctaOutline?: boolean;
  width?: string;
  height?: string;
  borderRadius?: string;
}>`
  text-align: center;
  ${baseCSS}
`;

const LinkButton = styled.a<{
  title?: string;
  danger?: boolean;
  outline?: boolean;
  outlineLight?: boolean;
  light?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  background?: string;
  color?: string;
  mb?: string;
  ml?: string;
  mr?: string;
  display?: string;
  jc?: string;
  ai?: string;
  mh?: string;
  pl?: string;
  icon?: boolean;
  cta?: boolean;
  ctaOutlineLight?: boolean;
  ctaOutline?: boolean;
  width?: string;
  height?: string;
  borderRadius?: string;
}>`
  ${baseCSS}
`;

const IconButtonInside: FunctionComponent<{
  text: string;
  Icon: any;
  iconSize?: any;
  iconMarginLeft?: any;
  makeSquare?: any;
}> = ({ text, Icon, iconSize, iconMarginLeft }) => {
  return (
    <>
      <div
        style={{
          position: 'absolute',
          marginLeft: iconMarginLeft ? iconMarginLeft : '-14px',
        }}
      >
        <img
          src={Icon}
          style={{
            verticalAlign: 'middle',
            height: iconSize || '39px',
          }}
        />
      </div>
      <div
        style={{
          width: '100%',
        }}
      >
        {text}
      </div>
    </>
  );
};

export { Button, LinkButton, RouterLinkButton, IconButtonInside };
