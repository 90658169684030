import React, { FunctionComponent, useState, useEffect } from 'react';
import { MdMenu } from 'react-icons/md';

import userdocks from '@userdocks/web-client-sdk';
import { StyledHeader, StyledMobileMenu } from './StyledHeader';
import { Link, HashLink } from '../Link';
import Container from '../Container';
import { Button } from '../Button';
import { websiteName } from '../../utils/websiteName';
import { StyledFlex } from '../../styles';
import { useLanguage } from '../../utils/language';
import { userdocksConfig } from '../../config/userdocks';

interface P {
  header?: any;
}

const nav = {
  pricing: {
    en: {
      pathname: '/en#pricing',
      text: 'Pricing',
    },
    de: {
      pathname: '/de#preise',
      text: 'Preise',
    },
  },
  docs: {
    en: {
      pathname: 'https://docs.userdocks.com',
      text: 'Documentation',
    },
    de: {
      pathname: 'https://docs.userdocks.com',
      text: 'Dokumentation',
    },
  },
  features: {
    en: {
      pathname: '/en#features',
      text: 'Features',
    },
    de: {
      pathname: '/de#features',
      text: 'Features',
    },
  },
  register: {
    en: 'Sign Up',
    de: 'Registrieren',
  },
  login: {
    en: 'Sign In',
    de: 'Login',
  },
};

const scrollWithOffset = (el, offset) => {
  const elementPosition = el.offsetTop - offset;
  window.scroll({
    top: elementPosition,
    left: 0,
    behavior: 'smooth',
  });
};

const Header: FunctionComponent<P> = ({ header }) => {
  const { language } = useLanguage();
  const [isSmall, setIsSmall] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleScroll = () => {
      if (
        document.body.scrollTop > 40
        || document.documentElement.scrollTop > 40
      ) {
        setIsSmall(true);
      } else {
        setIsSmall(false);
      }
    };
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('scroll', handleScroll, true);
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <StyledHeader isSmall={isSmall}>
        <Container>
          <StyledFlex width="100%" jc="space-between">
            <StyledFlex jc="flex-start">
              <HashLink
                fontWeight="700"
                to={`/${language}`}
                onClick={() => {
                  setIsMobileMenuOpen(false);
                  window.focus();
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  });
                }}
              >
                {websiteName}
              </HashLink>
            </StyledFlex>
            {windowSize.width < 768 && (
              <StyledFlex jc="flex-end">
                {/* eslint-disable-next-line */}
                <div
                  onClick={async () => setIsMobileMenuOpen(!isMobileMenuOpen)}
                >
                  <MdMenu size={35} />
                </div>
              </StyledFlex>
            )}
            {windowSize.width >= 768 && (
              <StyledFlex jc="flex-end">
                <HashLink
                  mr="1rem"
                  smooth
                  to={nav.pricing[language].pathname}
                  scroll={(el) => scrollWithOffset(el, 106)}
                >
                  {nav.pricing[language].text}
                </HashLink>
                <HashLink
                  mr="1rem"
                  smooth
                  to={nav.features[language].pathname}
                  scroll={(el) => scrollWithOffset(el, 106)}
                >
                  {nav.features[language].text}
                </HashLink>
                <Link
                  mr="1rem"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={nav.docs[language].pathname}
                >
                  {nav.docs[language].text}
                </Link>
                <Button
                  mb="0"
                  mr="1rem"
                  outline
                  onClick={async () => {
                    await userdocks.initialize(userdocksConfig);

                    return userdocks?.redirectTo({ type: 'signIn' });
                  }}
                >
                  {nav.login[language]}
                </Button>
                <Button
                  mb="0"
                  onClick={async () => {
                    await userdocks.initialize(userdocksConfig);

                    return userdocks?.redirectTo({ type: 'signUp' });
                  }}
                >
                  {nav.register[language]}
                </Button>
              </StyledFlex>
            )}
          </StyledFlex>
        </Container>
      </StyledHeader>
      {windowSize.width < 768 && isMobileMenuOpen && (
        <>
          <StyledMobileMenu isSmall={isSmall}>
            <StyledFlex fd="row" mb="2rem" mt="2rem">
              <Button
                mb="0"
                mr="1rem"
                outline
                id={header.login.id}
                data-client-id={header.login.clientId}
                data-redirect-uri={header.login.redirectUri}
              >
                Login
              </Button>
              <Button
                mb="0"
                id={header.signUp.id}
                data-client-id={header.signUp.clientId}
                data-redirect-uri={header.signUp.redirectUri}
              >
                {nav.register[language]}
              </Button>
            </StyledFlex>
            <StyledFlex fd="column">
              <HashLink
                mb="1rem"
                onClick={() => setIsMobileMenuOpen(false)}
                to={nav.pricing[language].pathname}
                scroll={(el) => scrollWithOffset(el, 106)}
              >
                {nav.pricing[language].text}
              </HashLink>
              <HashLink
                onClick={() => setIsMobileMenuOpen(false)}
                mb="1rem"
                to={nav.features[language].pathname}
                scroll={(el) => scrollWithOffset(el, 106)}
              >
                {nav.features[language].text}
              </HashLink>
              <Link
                mb="1rem"
                onClick={() => setIsMobileMenuOpen(false)}
                target="_blank"
                rel="noopener noreferrer"
                href={nav.docs[language].pathname}
              >
                {nav.docs[language].text}
              </Link>
            </StyledFlex>
          </StyledMobileMenu>
        </>
      )}
    </>
  );
};

export default Header;
