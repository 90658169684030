import { styled, css } from '../../theme';

const Container = styled.div<{
  forceMaxWidth?: number;
  slim?: boolean;
  medium?: boolean;
  fullWidthHeightCenter?: boolean;
  fullWidth?: boolean;
  mt?: string;
  dark?: boolean;
}>`
  max-width: ${({ theme, forceMaxWidth }) =>
    forceMaxWidth ? `${forceMaxWidth}px` : theme.metrics.maxWidth};
  padding: 0 ${({ theme }) => theme.metrics.bigMargin};
  margin: 0 auto;}
  ${({ slim }) =>
    slim &&
    css`
      max-width: ${({ theme }) => theme.metrics.maxWidthSlim};
    `}
  ${({ medium }) =>
    medium &&
    css`
      max-width: ${({ theme }) => theme.metrics.maxWidthMedium};
    `}
  ${({ dark }) =>
    dark &&
    css`
      background-color: ${({ theme }) => theme.colors.brandPrimary};
    `}
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      max-width: 100vw;
      width: 100vw;
    `}
  ${({ fullWidthHeightCenter }) =>
    fullWidthHeightCenter &&
    css`
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
    ${({ mt }) => mt && `margin-top: ${mt};`}
`;

export default Container;
