import { css } from 'styled-components';

import { device, theme } from '../theme';

const responsiveFont = ({
  minScreenSize = device.mobileM,
  maxScreenSize = device.laptop,
  minFontSize = 14,
  maxFontSize = 16,
} = {}) => {
  const unit = 'px';
  const [maxScreen] = maxScreenSize.match(/\d+/) || ['800'];
  const [minScreen] = minScreenSize.match(/\d+/) || ['400'];

  return css`
    /* prettier-ignore */
    font-size: calc(${minFontSize}${unit} + (${maxFontSize} - ${minFontSize}) * (100vw - ${minScreen}${unit}) / (${maxScreen} - ${minScreen}));
    @media ${minScreenSize.replace('min', 'max')} {
      /* prettier-ignore */
      font-size: ${minFontSize}${unit};
    }
    @media ${maxScreenSize} {
      /* prettier-ignore */
      font-size: ${maxFontSize}${unit};
    }
  `;
};

export const responsiveLine = ({
  color = theme.colors.brandSecondary,
  minScreenSize = device.mobileM,
  maxScreenSize = device.laptop,
  minFontSize = 14,
  maxFontSize = 16,
} = {}) => {
  const unit = 'px';
  const [maxScreen] = maxScreenSize.match(/\d+/) || ['800'];
  const [minScreen] = minScreenSize.match(/\d+/) || ['400'];

  return css`
    /* prettier-ignore */
    background-image: linear-gradient(white calc(${minFontSize}${unit} + (${maxFontSize} - ${minFontSize}) * (100vw - ${minScreen}${unit}) / (${maxScreen} - ${minScreen}) + 1px),  ${color} calc(${minFontSize}${unit} + (${maxFontSize} - ${minFontSize}) * (100vw - ${minScreen}${unit}) / (${maxScreen} - ${minScreen}) + -4px));
    @media ${minScreenSize.replace('min', 'max')} {
      /* prettier-ignore */
      background-image: linear-gradient(white calc(${minFontSize}${unit} + 1px),  ${color} calc(${minFontSize}${unit} + -4px));
    }
    @media ${maxScreenSize} {
      /* prettier-ignore */
      background-image: linear-gradient(white calc(${maxFontSize}${unit} + 3px),  ${color} calc(${maxFontSize}${unit} + 2px));
    }
  `;
};

export default responsiveFont;
