const currentYear = new Date().getFullYear();
const copyright =
  currentYear === 2020 ? `© ${currentYear}` : `© 2020-${currentYear}`;
const copyrightBy = {
  name: 'by Lukas Aichbauer, MSc',
  hrefs: [
    'https://userdocks.com',
    'https://api.userdocks.com',
    'https://sdk.userdocks.com',
    'https://docs.userdocks.com',
    'https://login.userdocks.com',
  ],
};

const i18n = {
  de: {
    imprint: {
      heading: 'Impressum',
      company: 'userdocks',
      person: 'Lukas Aichbauer, MSc',
      address: {
        street: 'Seidlgasse',
        housenumber: '7/9',
        zipCode: '1030',
        city: 'Wien',
        country: 'Austria',
      },
      vat: {
        key: 'UID:',
        value: 'ATU73953448',
      },
      chamberOfCommerce: 'Mitglied der Wirtschaftskammer Wien (WKO Wien)',
      service:
        'Dienstleistungen in der automatischen Datenverarbeitung und Informationstechnik',
      jurisdiction: {
        key: 'Gerichtsstand:',
        value: 'Bezriksgericht Wien Innere Stad (BZG Wien Innere Stadt)',
      },
      copyright,
      copyrightBy: {
        ...copyrightBy,
        text:
          'Das Impressum und die Datenschutzerklärung gilt für folgenden Domains:',
      },
    },
    privacyPolicy: {
      heading: 'Datenschutzerklärung',
      link: {
        url: '/de/datenschutz',
        text: 'Datenschutzerklärung',
      },
      text: ['Bitte lesen Sie unsere', 'für alle Informationen.'],
    },
    terms: {
      heading: 'Allgemeine Geschäftsbedingungen',
      link: {
        url: '/de/agb',
        text: 'allgemeinen Geschäftsbedingungen',
      },
      text: ['Bitte lesen Sie unsere', 'für alle Informationen.'],
    },
  },
  en: {
    imprint: {
      heading: 'Imprint',
      company: 'userdocks',
      person: 'Lukas Aichbauer, MSc',
      address: {
        street: 'Seidlgasse',
        housenumber: '7/9',
        zipCode: '1030',
        city: 'Wien',
        country: 'Austria',
      },
      vat: {
        key: 'VAT:',
        value: 'ATU73953448',
      },
      chamberOfCommerce: 'Member of the Chamber of Commerce Vienna (WKO Wien)',
      service:
        'Services in automatic data processing and information technology',
      jurisdiction: {
        key: 'Jurisdiction:',
        value: 'District Court Vienna Inner City (BZG Wien Innere Stadt)',
      },
      copyright,
      copyrightBy: {
        ...copyrightBy,
        text:
          'The imprint and the privacy policy is valid for the following domains:',
      },
    },
    privacyPolicy: {
      heading: 'Privacy Policy',
      link: {
        url: '/en/privacy',
        text: 'privacy policy',
      },
      text: ['Please check our', 'for all infos.'],
    },
    terms: {
      heading: 'Terms and Conditions of Use',
      link: {
        url: '/en/terms',
        text: 'term and conditions of use',
      },
      text: ['Please check our', 'for all infos.'],
    },
  },
};

export default i18n;
