"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "useI18n", {
  enumerable: true,
  get: function get() {
    return _useI18n["default"];
  }
});

var _useI18n = _interopRequireDefault(require("./useI18n"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }