import { useContext } from 'react';
import LanguageContext from './LanguageContext';

const useLanguage = () => {
  const context = useContext(LanguageContext);

  return context;
};

export default useLanguage;
