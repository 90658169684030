import LandingPage from '../views/LandingPage';
import Imprint from '../../../views/ImprintPage';
import PrivacyPage from '../../../views/PrivacyPage';
import Terms from '../../../views/Terms';
import Pricing from '../../../views/Pricing';
import Features from '../../../views/Features';
import HelpCenter from '../../../views/HelpCenter';

const GERMAN = 'de';
const ENGLISH = 'en';

const routes = [
  {
    component: LandingPage,
    path: `/${ENGLISH}`,
  },
  {
    component: LandingPage,
    path: `/${GERMAN}`,
  },
  {
    component: Imprint,
    path: `/${ENGLISH}/imprint`,
  },
  {
    component: Imprint,
    path: `/${GERMAN}/impressum`,
  },
  {
    component: PrivacyPage,
    path: `/${ENGLISH}/privacy`,
  },
  {
    component: PrivacyPage,
    path: `/${GERMAN}/datenschutz`,
  },
  {
    component: Terms,
    path: `/${ENGLISH}/terms`,
  },
  {
    component: Terms,
    path: `/${GERMAN}/agb`,
  },
  {
    component: Pricing,
    path: `/${ENGLISH}/pricing`,
  },
  {
    component: Pricing,
    path: `/${GERMAN}/preise`,
  },
  {
    component: Features,
    path: `/${ENGLISH}/features`,
  },
  {
    component: Features,
    path: `/${GERMAN}/features`,
  },
  {
    component: HelpCenter,
    path: `/${ENGLISH}/help-center`,
  },
  {
    component: HelpCenter,
    path: `/${GERMAN}/hilfe`,
  },
];

export default routes;
