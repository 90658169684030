"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "browserLanguage", {
  enumerable: true,
  get: function get() {
    return _browserLanguage.browserLanguage;
  }
});
Object.defineProperty(exports, "deepMerge", {
  enumerable: true,
  get: function get() {
    return _deepMerge.deepMerge;
  }
});
Object.defineProperty(exports, "getFallback", {
  enumerable: true,
  get: function get() {
    return _getFallback.getFallback;
  }
});
Object.defineProperty(exports, "getI18n", {
  enumerable: true,
  get: function get() {
    return _getI18n.getI18n;
  }
});
Object.defineProperty(exports, "getLanguage", {
  enumerable: true,
  get: function get() {
    return _getLanguage.getLanguage;
  }
});

var _browserLanguage = require("./browserLanguage");

var _deepMerge = require("./deepMerge");

var _getFallback = require("./getFallback");

var _getI18n = require("./getI18n");

var _getLanguage = require("./getLanguage");