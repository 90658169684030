import React, { useState, FunctionComponent } from 'react';
import lang from './getLanguage';

interface IProps {
  children: any;
  language: 'en' | 'de';
}

const LanguageContext = React.createContext({
  language: lang(),
  setLanguage: (_) => {},
});

export const LanguageProvider: FunctionComponent<IProps> = ({
  children,
  language,
}) => {
  const [currentLanguage, setCurrentLanguage] = useState(language || lang());

  const setLang = (newLang) => {
    setCurrentLanguage(newLang);
  };

  return (
    <LanguageContext.Provider
      value={{
        language: currentLanguage,
        setLanguage: setLang,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const LanguageConsumer = LanguageContext.Consumer;

export default LanguageContext;
