const i18n = {
  de: {
    pricing: {
      heading: 'Preise',
      yearly: 'Jährliche Zahlung',
      dev: {
        heading: 'Development',
        description:
          'Für das entwickeln Ihrer Applikation und das einbinden von userdocks in einem lokalen Development Environment fallen keine Kosten an. Erst wenn Ihre App live geht fallen die ersten Kosten an. Die Nutzung von userdocks im Development besteht auf einer fairen Nutzung der Bandbreite, Speicherkapazität und des Requestvolumens. userdocks erlaubt keine automatisierten Tests in jeglicher Form. Bei Verstößen gegen die faire Nutzung behält sich userdocks das Recht vor den Teilnehmer von der Nutzung auszuschließen.',
        base: {
          price: '€ 0,00 pro Monat',
          priceYear: '€ 0,00 pro Jahr',
          text: 'Eine lokale App mit lokalen Nutzern',
        },
        additionalUser: {
          price: '€ 0,00 pro Monat',
          priceYear: '€ 0,00 pro Jahr',
          text: 'Eine lokale App mit lokalen Nutzern',
        },
      },
      internal: {
        heading: 'Interne Nutzer',
        description:
          'Interne Nutzer beschreiben Mitarbeiter des eigenen Unternehmen die eine private App auf userdocks verwenden',
        base: {
          price: '€ 1.500,00 pro Monat',
          priceYear: '€ 16.500,00 pro Jahr',
          text: 'Eine App mit bis zu 499 Nutzern',
        },
        additionalUser: {
          price: '€ 5,00 pro Monat',
          priceYear: '€ 55,00 pro Jahr',
          text: 'Jeder weitere Nutzer pro App',
        },
      },
      buttons: [
        {
          text: 'Dokumentation',
          title: 'Lesen Sie die userdocks Dokumentation',
        },
        {
          text: 'Kostenlos testen',
          title: 'Jetzt userdocks kostenlos testen',
        },
      ],
      external: {
        heading: 'Externe Nutzer',
        description:
          'Externe Nutzer beschreiben alle Nutzer die eine öffentlich zugängliche App auf userdocks verwenden',
        base: {
          price: '€ 150,00 pro Monat',
          priceYear: '€ 1.650,00 pro Jahr',
          text: 'Eine App mit bis zu 499 Nutzern',
        },
        additionalUser: {
          price: '€ 0,70 pro Monat',
          priceYear: '€ 7,70 pro Jahr',
          text: 'Jeder weitere Nutzer pro App',
        },
      },
    },
  },
  en: {
    pricing: {
      heading: 'Pricing',
      yearly: 'Yearly payment',
      dev: {
        heading: 'Development',
        description:
          'There are no costs for developing your application and integrating userdocks into the local development environment. The first costs are only incurred when your app goes live. The use of userdocks in development insists on a fair use of bandwidth, storage capacity and request volume. userdocks does not allow automated tests in any form. In the event of violations of fair usage, userdocks reserves the right to exclude the participant from usage.',
        base: {
          price: '$ 0,00 per month',
          priceYear: '$ 0,00 per year',
          text: 'One app with local users',
        },
        additionalUser: {
          price: '$ 0,00 per month',
          priceYear: '$ 0,00 per year',
          text: 'One app with local users',
        },
      },
      internal: {
        heading: 'Internal User',
        description:
          'Internal users describe employees of your own company who use a private app on userdocks',
        base: {
          price: '$ 1,800.00 per month',
          priceYear: '$ 19,800.00 per year',
          text: 'One app with up to 499 users',
        },
        additionalUser: {
          price: '$ 6.00 per month',
          priceYear: '$ 66.00 per year',
          text: 'Every additional user per app',
        },
      },
      external: {
        heading: 'External User',
        description:
          'External users describe all users who use a publicly accessible app on userdocks',
        base: {
          price: '$ 180.00 per month',
          priceYear: '$ 1,980.00 per year',
          text: 'One app with up to 499 users',
        },
        additionalUser: {
          price: '$ 1.00 per month',
          priceYear: '$ 11.00 per year',
          text: 'Every additional user per app',
        },
      },
      buttons: [
        {
          text: 'Read the docs',
          title: 'Read the userdocks documentation',
        },
        {
          text: 'Try for free',
          title: 'Try userdocks for free',
        },
      ],
    },
  },
};

export default i18n;
