import { styled, css } from '../../theme';

const hidden = (props) =>
  css`
    ${props.hidden ? 'display: none;' : ''}
  `;

export const StyledForm = styled.form<{ width?: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || '100%'};
`;

export const StyledCheckbox = styled.input<{
  invalid?: boolean;
  borderRadius?: string;
}>`
  display: inline-block;
  margin-top: 6px;
  line-height: 1;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
  &::placeholder {
    color: ${({ theme }) => theme.colors.fontPrimary};
    opacity: 0.7;
  }
`;

export const StyledError = styled.div`
  color: ${({ theme }) => theme.colors.red};
  margin-bottom: 1rem;
  font-size: 0.8rem;
  padding-left: 2px;
  max-width: 100%;
`;

export const StyledInput = styled.input<{
  invalid?: boolean;
  borderRadius?: string;
}>`
  display: block;
  outline: 0;
  border: 1px solid
    ${({ theme, invalid }) => (invalid ? theme.colors.red : theme.colors.gray)};
  ${({ theme, borderRadius }) => theme.snippets.borderRadius(borderRadius)};
  transition: border-color 0.3s;
  padding: 0.5rem;
  width: 100%;
  line-height: 1.4;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      background-color: ${({ theme }) => theme.colors.lightgray};
    `}

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray};
    opacity: 0.7;
  }
`;

export const StyledSelect = styled.select.attrs({
  select: true,
  noRadius: true,
})<{ invalid?: boolean; borderRadius?: string }>`
  display: block;
  background-color: white;
  outline: 0;
  border: 1px solid
    ${({ theme, invalid }) =>
      invalid ? theme.colors.red : theme.colors.brandPrimary};
  ${({ theme, borderRadius }) => theme.snippets.borderRadius(borderRadius)};
  padding: 0.5rem;
  width: 100%;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      background-color: ${({ theme }) => theme.colors.lightgray};
    `}
  &::placeholder {
    color: ${({ theme }) => theme.colors.fontPrimary};
    opacity: 0.7;
  }
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" version="1"><path d="M4 8L0 4h8z"/></svg>');
  padding-right: 1.2rem;
  ${hidden}
`;

export const StyledTextarea = styled.textarea<{
  invalid?: boolean;
  borderRadius?: string;
}>`
  display: block;
  outline: 0;
  border: 1px solid
    ${({ theme, invalid }) =>
      invalid ? theme.colors.red : theme.colors.brandPrimary};
  ${({ theme, borderRadius }) => theme.snippets.borderRadius(borderRadius)};
  padding: 0.5rem;
  width: 100%;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
  &::placeholder {
    color: ${({ theme }) => theme.colors.fontPrimary};
    opacity: 0.7;
  }
`;

export const StyledFormGroup = styled.div<{
  mb?: string;
  fd?: string;
  jc?: string;
  alignSelf?: string;
}>`
  display: flex;
  flex-direction: ${({ fd }) => fd || 'column'};
  justify-content: ${({ jc }) => jc};
  align-self: ${({ alignSelf }) => alignSelf};

  margin-bottom: ${({ theme, mb }) => mb || theme.metrics.margin};

  :last-child {
    margin-bottom: 0;
  }
`;

export const StyledInlineButtonInput = styled.div`
  display: flex;
  & > input {
    border-radius: ${({ theme }) =>
      `${theme.metrics.borderRadius} 0 0 ${theme.metrics.borderRadius}`};
  }
  & > button {
    border-radius: ${({ theme }) =>
      `0 ${theme.metrics.borderRadius} ${theme.metrics.borderRadius} 0`};
  }
`;

export const StyledLabel = styled.label<{
  wb?: string;
  ws?: string;
  display?: string;
  checkboxMultiLinePadding?: boolean;
}>`
  display: block;
  word-break: ${({ wb }) => wb};
  white-space: ${({ ws }) => ws};
  display: ${({ display }) => display};

  + input,
  + textarea,
  + select {
    margin-top: 3px;
  }

  & > span > span {
    padding-left: 0.5rem;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    font-size: 0.8rem;
  }
`;

export const StyledLineText = styled.div<{
  borderRadius?: string;
}>`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  line-height: 0.1rem;
  margin: 10px 0 20px;
  & > span {
    background: ${({ theme }) => theme.colors.white};
    padding: 0 10px;
  }
`;
export const StyledToggle = styled.label<{
  borderRadius?: string;
}>`
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
  & > input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  & > input[value='true'] + span {
    background-color: ${({ theme }) => theme.colors.brandPrimary};
  }
  & > input:focus + span {
    box-shadow: 0 0 1px ${({ theme }) => theme.colors.brandPrimary};
  }
  & > input[value='true'] + span:before {
    transform: translateX(16px);
  }
  & > span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    ${({ theme, borderRadius }) => theme.snippets.borderRadius(borderRadius)};

    :before {
      position: absolute;
      content: '';
      height: 12px;
      width: 12px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      transition: 0.4s;
      border-radius: 4px; /* This needs to be 4px!!! */
    }
  }
`;
