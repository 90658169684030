import React, { FunctionComponent } from 'react';
import { useI18n } from 'react-i18n-kit';

import Container from '../../components/Container';
import Accordion from '../../components/Accordion';
import Text from '../../components/Text';
import {
  StyledCard,
  StyledCardBody,
  StyledCardHeader,
  StyledFlex,
} from '../../styles';
import data from './i18nHelpCenter';
import { useLanguage } from '../../utils/language';

export interface I18n {
  i18n: typeof data['de'];
}

const HelpCenter: FunctionComponent = () => {
  const { language } = useLanguage();
  const { i18n }: I18n = useI18n(data, {
    lang: language,
  });

  return (
    <Container mt="calc(90px + 1rem)" slim>
      <StyledCard>
        <StyledCardHeader>
          <StyledFlex>
            <h1>{i18n.title}</h1>
          </StyledFlex>
        </StyledCardHeader>
        <StyledCardBody>
          <Text>{i18n.text}</Text>
        </StyledCardBody>
        <StyledCardBody>
          <Accordion
            title={i18n.faqTitle}
            list={i18n.list}
            options={{
              openItem: 'help1',
              alwaysOpen: true,
            }}
          />
        </StyledCardBody>
      </StyledCard>
    </Container>
  );
};

export default HelpCenter;
