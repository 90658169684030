type LanguageType = 'en' | 'de';

export const splittedLocationPathname = window.location.pathname
  .replace('/legacy', '')
  .split('/')
  .filter((item: string) => item !== '');

export const hasLanguage =
  Array.isArray(splittedLocationPathname) && splittedLocationPathname.length > 0
    ? splittedLocationPathname[0] === 'en' ||
      splittedLocationPathname[0] === 'de'
    : false;

const language = () => {
  const returnValue = hasLanguage
    ? splittedLocationPathname[0]
    : localStorage.getItem('lang') ||
      localStorage.getItem('language') ||
      (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      'en';

  return returnValue;
};

const thisLanguage = language();

const lang = (): LanguageType => {
  const theLang = thisLanguage.includes('-')
    ? thisLanguage.split('-')[0]
    : thisLanguage;

  if (theLang === 'en' || theLang === 'de') {
    return theLang;
  }

  return 'en';
};

export default lang;
