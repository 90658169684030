import hasLang from './hasLang';

const redirectLanguage = (history: any, location: any) => {
  const { hasLanguage, path, lang } = hasLang(location);

  if (!hasLanguage) {
    let extra = '';

    if (Array.isArray(path) && path.length > 0) {
      extra = `/${path}`;
    }

    history.push(`/${lang}${extra}`);
    window.location.reload();
  }
};

export default redirectLanguage;
