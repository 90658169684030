"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "withI18n", {
  enumerable: true,
  get: function get() {
    return _components.withI18n;
  }
});
Object.defineProperty(exports, "useI18n", {
  enumerable: true,
  get: function get() {
    return _hooks.useI18n;
  }
});

var _components = require("./components");

var _hooks = require("./hooks");