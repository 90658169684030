import { ApolloClient, InMemoryCache } from '@apollo/client';

import { protocol, baseAPILink } from '../../../utils/url';

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: `${protocol}${baseAPILink}/graphql`,
});

export default client;
