import React, { FunctionComponent, useState } from 'react';
import { useI18n } from 'react-i18n-kit';
import {
  AiFillFacebook,
  AiOutlineTwitter,
  AiOutlineGoogle,
  AiFillGithub,
  AiOutlineUsergroupAdd,
  AiOutlineLink,
  AiOutlineKey,
  AiOutlineUserAdd,
  AiOutlineMail,
  AiOutlineControl,
  AiOutlineApi,
  AiOutlineCreditCard,
} from 'react-icons/ai';

import { Link, RouterLink } from '../../components/Link';
import Helmet from '../../components/Helmet';
import Container from '../../components/Container';
import { Button } from '../../components/Button';
import {
  StyledCard,
  StyledCardHeader,
  StyledCardBody,
  StyledFlex,
  StyledRow,
  StyledFlexGrid,
  StyledFlexGridCol,
  StyledDiv,
} from '../../styles';
import { StyledToggle } from '../../components/Forms/StyledForm';
import { appIdPublic } from '../../utils/appId';
import { apiRoutes } from '../../utils/url';
import Text from '../../components/Text';
import data from './i18nPricing';
import { useLanguage } from '../../utils/language';

export interface I18n {
  i18n: typeof data['de'];
}

const Pricing: FunctionComponent<{
  noMarginTop?: boolean;
  noHelmet?: boolean;
}> = ({ noMarginTop, noHelmet }) => {
  const { language } = useLanguage();
  const { i18n }: I18n = useI18n(data, {
    lang: language,
  });
  const [yearly, setYearly] = useState(true);

  return (
    <>
      <Container mt={noMarginTop ? '' : 'calc(90px + 1rem)'}>
        {!noHelmet && <Helmet page="pricing" />}
        <StyledFlex>
          <Text useType="h2" id={language === 'en' ? 'pricing' : 'preise'}>
            {i18n.pricing.heading}
          </Text>
        </StyledFlex>
        <StyledFlex>
          <StyledFlex mb="2rem" width="200px" jc="space-between">
            {i18n.pricing.yearly}
            <StyledToggle>
              <input
                type="checkbox"
                checked={yearly}
                value={yearly.toString()}
                onChange={() => {
                  setYearly(!yearly);
                }}
              />
              <span></span>
            </StyledToggle>
          </StyledFlex>
        </StyledFlex>
        <StyledFlexGrid>
          <StyledFlexGridCol noc={3}>
            <StyledCard mb="1rem">
              <StyledCardHeader>
                <Text useType="h2">{i18n.pricing.dev.heading}</Text>
              </StyledCardHeader>
              <StyledCardHeader>
                <Text useType="h3">
                  {i18n.pricing.dev.base[yearly ? 'priceYear' : 'price']}
                </Text>
                <Text>{i18n.pricing.dev.base.text}</Text>
              </StyledCardHeader>
              <StyledCardHeader>
                <Text useType="h3">
                  {
                    i18n.pricing.dev.additionalUser[
                      yearly ? 'priceYear' : 'price'
                    ]
                  }
                </Text>
                <Text>{i18n.pricing.dev.additionalUser.text}</Text>
              </StyledCardHeader>
              <StyledCardBody>
                <Text>{i18n.pricing.dev.description}</Text>
              </StyledCardBody>
            </StyledCard>
          </StyledFlexGridCol>
          <StyledFlexGridCol noc={3}>
            <StyledCard mb="1rem">
              <StyledCardHeader>
                <Text useType="h2">{i18n.pricing.external.heading}</Text>
              </StyledCardHeader>
              <StyledCardHeader>
                <Text useType="h3">
                  {i18n.pricing.external.base[yearly ? 'priceYear' : 'price']}
                </Text>
                <Text>{i18n.pricing.external.base.text}</Text>
              </StyledCardHeader>
              <StyledCardHeader>
                <Text useType="h3">
                  {
                    i18n.pricing.external.additionalUser[
                      yearly ? 'priceYear' : 'price'
                    ]
                  }
                </Text>
                <Text>{i18n.pricing.external.additionalUser.text}</Text>
              </StyledCardHeader>
              <StyledCardBody>
                <Text>{i18n.pricing.external.description}</Text>
              </StyledCardBody>
            </StyledCard>
          </StyledFlexGridCol>
          <StyledFlexGridCol noc={3}>
            <StyledCard mb="1rem">
              <StyledCardHeader>
                <Text useType="h2">{i18n.pricing.internal.heading}</Text>
              </StyledCardHeader>
              <StyledCardHeader>
                <Text useType="h3">
                  {i18n.pricing.internal.base[yearly ? 'priceYear' : 'price']}
                </Text>
                <Text>{i18n.pricing.internal.base.text}</Text>
              </StyledCardHeader>
              <StyledCardHeader>
                <Text useType="h3">
                  {
                    i18n.pricing.internal.additionalUser[
                      yearly ? 'priceYear' : 'price'
                    ]
                  }
                </Text>
                <Text>{i18n.pricing.internal.additionalUser.text}</Text>
              </StyledCardHeader>
              <StyledCardBody>
                <Text>{i18n.pricing.internal.description}</Text>
              </StyledCardBody>
            </StyledCard>
          </StyledFlexGridCol>
        </StyledFlexGrid>
      </Container>
    </>
  );
};

export default Pricing;
