"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.browserLanguage = void 0;

var getLanguage = function getLanguage() {
  var browserLanguage;

  try {
    browserLanguage = navigator.languages && navigator.languages[0] || navigator.language || navigator.userLanguage;
  } catch (err) {
    browserLanguage = "en";
  }

  return browserLanguage;
};

var browserLanguage = getLanguage();
exports.browserLanguage = browserLanguage;