import React, { FunctionComponent } from 'react';
import { useI18n } from 'react-i18n-kit';
import { useLanguage } from '../../utils/language';
import { Helmet as ReactHelmet } from 'react-helmet-async';
import i18nHelmet from './i18nHelmet';
import merge from 'lodash.merge';

export interface P {
  data?: any;
  page: string;
}

const Helmet: FunctionComponent<P> = ({ data, page }) => {
  const myData = merge(i18nHelmet, data);
  const { language } = useLanguage();
  const { i18n } = useI18n(myData, {
    lang: language,
  });

  const meta = i18n[page];

  return (
    <ReactHelmet>
      {/* <!-- HTML start --> */}
      <html lang={language} />
      {/* <!-- HTML end --> */}

      {/* <!-- Meta start --> */}
      <title>{meta?.title}</title>
      <meta name="description" content={meta?.description} />
      {/* <!-- Meta end --> */}

      {/* <!-- Open Graph start --> */}
      <meta property="og:title" content={meta?.og?.title} />
      <meta property="og:site_name" content={meta?.og?.site_name} />
      <meta property="og:type" content={meta?.og?.type || 'website'} />
      <meta property="og:image" content={meta?.og?.image} />
      <meta property="og:url" content={meta?.og?.url} />
      <meta property="og:description" content={meta?.og?.description} />
      {/* <!-- Open Graph end --> */}

      {/* <!-- Twitter Card start --> */}
      <meta
        name="twitter:card"
        content={meta?.twitter?.card || 'summary_large_image'}
      />
      <meta
        name="twitter:site"
        content={meta?.twitter?.site || '@steamscraper'}
      />
      <meta
        name="twitter:creator"
        content={meta?.twitter?.creator || '@steamscraper'}
      />
      <meta name="twitter:title" content={meta?.twitter?.title} />
      <meta name="twitter:description" content={meta?.twitter?.description} />
      <meta name="twitter:image" content={meta?.twitter?.image} />
      {/* <!-- Twitter Card end --> */}
    </ReactHelmet>
  );
};

export default Helmet;
