"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFallback = void 0;

var getFallback = function getFallback(options, props) {
  if (props && props.fallback) {
    return props.fallback;
  }

  return options.fallback || "en";
};

exports.getFallback = getFallback;