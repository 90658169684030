"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "withI18n", {
  enumerable: true,
  get: function get() {
    return _withI18n["default"];
  }
});
exports["default"] = void 0;

var _withI18n = _interopRequireDefault(require("./withI18n"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = _withI18n["default"];
exports["default"] = _default;