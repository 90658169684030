import React, { FunctionComponent } from 'react';
import { StyledH1, StyledH2, StyledH3, StyledP } from './StyledText';

export interface P {
  useType?: 'p' | 'h1' | 'h2' | 'h3';
  light?: boolean;
  ta?: string;
  id?: string;
  mt?: string;
  sub?: boolean;
}

const Text: FunctionComponent<P> = ({
  id,
  children,
  useType,
  light,
  ta,
  mt,
  sub,
}) => {
  let Wrapper:
    | typeof StyledH1
    | typeof StyledH2
    | typeof StyledH3
    | typeof StyledP;

  switch (useType) {
    case 'h1':
      Wrapper = StyledH1;
      break;

    case 'h2':
      Wrapper = StyledH2;
      break;

    case 'h3':
      Wrapper = StyledH3;
      break;

    case 'p':
    default:
      Wrapper = StyledP;
  }

  return (
    <Wrapper id={id} light={light} ta={ta} mt={mt} sub={sub}>
      {children}
    </Wrapper>
  );
};

export default Text;
