import React, { FunctionComponent } from 'react';
import { useI18n } from 'react-i18n-kit';

import Container from '../../components/Container';
import Helmet from '../../components/Helmet';
import Text from '../../components/Text';
import { Link } from '../../components/Link';
import data from './i18nPrivacyPage';
import { useLanguage } from '../../utils/language';
import { StyledCard, StyledCardBody } from '../../styles';

export interface I18n {
  i18n: typeof data['de'];
}

const PrivacyPage: FunctionComponent = () => {
  const { language } = useLanguage();
  const { i18n }: I18n = useI18n(data, {
    lang: language,
  });

  return (
    <Container mt="calc(90px + 1rem)" slim>
      <StyledCard>
        <StyledCardBody>
          <Helmet page="privacy" />

          <Text useType="h1">{i18n.privacyPolicy.heading}</Text>

          {i18n.privacyPolicy.text.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          <Text useType="h2">{i18n.privacyPolicy.contactWithUs.heading}</Text>

          {i18n.privacyPolicy.contactWithUs.text.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          <Text useType="h2">{i18n.privacyPolicy.dataStorage.heading}</Text>

          {i18n.privacyPolicy.dataStorage.text.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          <ul>
            {i18n.privacyPolicy.dataStorage.list.map((item: string) => (
              <li key={item.split(' ').join('-')}>{item}</li>
            ))}
          </ul>

          {i18n.privacyPolicy.dataStorage.text1.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          <ul>
            {i18n.privacyPolicy.dataStorage.list1.map((item: string) => (
              <li key={item.split(' ').join('-')}>{item}</li>
            ))}
          </ul>

          {i18n.privacyPolicy.dataStorage.text2.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          <ul>
            {i18n.privacyPolicy.dataStorage.list2.map((item: string) => (
              <li key={item.split(' ').join('-')}>{item}</li>
            ))}
          </ul>

          {i18n.privacyPolicy.dataStorage.text3.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          <ul>
            {i18n.privacyPolicy.dataStorage.list3.map((item: string) => (
              <li key={item.split(' ').join('-')}>{item}</li>
            ))}
          </ul>

          {i18n.privacyPolicy.dataStorage.text4.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          {i18n.privacyPolicy.dataStorage.text5.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          <ul>
            {i18n.privacyPolicy.dataStorage.list4.map((item: string) => (
              <li key={item.split(' ').join('-')}>{item}</li>
            ))}
          </ul>

          {i18n.privacyPolicy.dataStorage.text6.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          <ul>
            {i18n.privacyPolicy.dataStorage.list5.map((item: string) => (
              <li key={item.split(' ').join('-')}>{item}</li>
            ))}
          </ul>

          {i18n.privacyPolicy.dataStorage.text7.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          <ul>
            {i18n.privacyPolicy.dataStorage.list6.map((item: string) => (
              <li key={item.split(' ').join('-')}>{item}</li>
            ))}
          </ul>

          {i18n.privacyPolicy.dataStorage.text8.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          <Text useType="h2">{i18n.privacyPolicy.cookies.heading}</Text>

          {i18n.privacyPolicy.cookies.text.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          <ul>
            {i18n.privacyPolicy.cookies.list.map((item: string) => (
              <li key={item.split(' ').join('-')}>{item}</li>
            ))}
          </ul>

          <Text useType="h2">
            {i18n.privacyPolicy.newsletterMailChimp.heading}
          </Text>

          {i18n.privacyPolicy.newsletterMailChimp.text.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          <Text useType="h2">{i18n.privacyPolicy.paymentStripe.heading}</Text>

          {i18n.privacyPolicy.paymentStripe.text.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          <ul>
            {i18n.privacyPolicy.paymentStripe.list.map((item: string) => (
              <li key={item.split(' ').join('-')}>{item}</li>
            ))}
          </ul>

          {i18n.privacyPolicy.paymentStripe.text1.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          <Text useType="h2">{i18n.privacyPolicy.yourRights.heading}</Text>

          {i18n.privacyPolicy.yourRights.text.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          <Text useType="h2">{i18n.privacyPolicy.contact.heading}</Text>

          {i18n.privacyPolicy.contact.text.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          {i18n.privacyPolicy.date.text.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}
        </StyledCardBody>
      </StyledCard>
    </Container>
  );
};

export default PrivacyPage;
