import { Link as ReactRouterLink } from 'react-router-dom';
import { HashLink as ReactRouterHashLink } from 'react-router-hash-link';
import { rgba } from 'polished';

import { styled, css } from '../../theme';

const StyledA = css<{
  isLight?: boolean;
  noMargin?: boolean;
  noMarginRight?: boolean;
  noMarginLeft?: boolean;
  mr?: string;
  ml?: string;
  mb?: string;
  fontWeight?: string;
  secondaryColor?: string;
}>`
  color: ${({ theme }) => theme.colors.fontPrimary};
  margin-right: ${({ noMarginRight, noMargin, mr }) =>
    noMargin || noMarginRight ? '' : mr || '0.4rem'};
  margin-left: ${({ noMarginLeft, noMargin, ml }) =>
    noMargin || noMarginLeft ? '' : ml || '0.4rem'};
  margin-bottom: ${({ mb }) => mb};
  text-decoration: none;
  line-height: 1.4;
  cursor: pointer;

  font-weight: ${({ fontWeight }) => fontWeight || '300'};

  will-change: background-position;
  position: relative;

  background-image: linear-gradient(
    transparent 0%,
    transparent calc(50% - 9px),
    ${({ theme, secondaryColor }) =>
        rgba(secondaryColor || theme.colors.brandSecondary, 0.4)}
      calc(50% - 9px),
    ${({ theme, secondaryColor }) =>
        rgba(secondaryColor || theme.colors.brandSecondary, 0.4)}
      100%
  );

  transition: background-position 0.3s ease-in-out, color 0.3s ease-in-out;
  background-size: 100% 200%;
  background-position: 0 0;
  word-break: break-word;

  &:hover {
    color: black;
    background-image: linear-gradient(
      transparent 0%,
      transparent calc(50% - 9px),
      ${({ theme, secondaryColor }) =>
          rgba(secondaryColor || theme.colors.brandSecondary, 0.7)}
        calc(50% - 9px),
      ${({ theme, secondaryColor }) =>
          rgba(secondaryColor || theme.colors.brandSecondary, 0.7)}
        100%
    );
    background-position: 0 100%;
  }

  ${({ isLight, theme, secondaryColor }) =>
    isLight &&
    css`
      color: ${theme.colors.fontSecondary};
      background-image: linear-gradient(
        transparent 0%,
        transparent calc(50% - 9px),
        ${rgba(secondaryColor || theme.colors.brandSecondary, 0.8)}
          calc(50% - 9px),
        ${rgba(secondaryColor || theme.colors.brandSecondary, 0.8)} 100%
      );

      &:hover {
        color: black;
        background-image: linear-gradient(
          transparent 0%,
          transparent calc(50% - 9px),
          ${secondaryColor || theme.colors.brandSecondary} calc(50% - 9px),
          ${secondaryColor || theme.colors.brandSecondary} 100%
        );
        background-position: 0 100%;
      }
    `}
`;

export const RouterLink = styled(ReactRouterLink)`
  ${StyledA};
`;

export const HashLink = styled(ReactRouterHashLink)`
  ${StyledA};
`;

export const Link = styled.a`
  ${StyledA};
`;
