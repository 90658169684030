import i18n from '../../../../views/HelpCenter/i18nHelpCenter';

const rand = Math.round(Math.random());

const data = {
  de: {
    form: {
      email: {
        label: 'E-Mail-Adresse *',
        placeholder:
          rand === 0
            ? 'erika.mustermann@email.com'
            : 'max.mustermann@email.com',
        error: 'Sie müssen eine valide E-Mail-Adresse angeben.',
      },
      button: {
        text: 'Kostenlos testen',
        sub: 'Keine Kreditkarte notwendig!',
      },
    },
    landingPage: {
      heading: {
        1: 'Identitäts- & Zugriffsverwaltung',
        2: 'für Ihre Web-Apps!',
      },
      subHeading: {
        1: 'Integrieren Sie Authentifizierung und Autorisierung',
        2: 'innerhalb von Minuten in Ihre Web-App oder statische Website.',
      },
    },
    firstSection: {
      heading: {
        1: 'Erstellen Sie einen Login für',
        2: 'Ihre Webapp in nur wenigen Minuten!',
      },
      text: [
        'Fügen Sie die userdocks SDK und zwei Buttons mit spezifischen Attributen zu Ihrer Web-App hinzu und Sie haben den einfachsten Login Flow implementiert.',
      ],
    },
    secondSection: {
      heading: {
        1: 'Ein paar Code-Zeilen später, ...',
      },
      text: [
        '... erlauben Sie Ihren Nutzern sich mit einer E-Mail-Adresse oder mit GitHub, Google, Twitter oder Facebook einzuloggen und zu registrieren',
        '... erlauben Sie Ihren Nutzern über Sessions eingeloggt zu bleiben. Diesen Zeitraum können Sie über das userdocks Dashboard steuern.',
        '... können Sie die Identität Ihrer Nutzer immer über eine E-Mail bestätigen lassen. Nutzern müssen Ihren AGB und Ihrer Datenschutzerklärung zustimmen.',
        '... sollten Ihre Nutzer das Passwort vergessen können Sie dieses jederzeit auf der Login-Seite von userdocks zurücksetzen.',
      ],
    },
    thirdSection: {
      heading: {
        1: 'Starte Sie noch jetzt damit, die Nutzer',
        2: 'Ihrer Web-Apps zu Authentifizieren & Autorisieren.',
      },
      button: {
        text: 'Jetzt kostenlos testen',
        title: 'Jetzt userdocks kostenlos testen',
      },
      text: ['Keine Kreditkarte notwendig!'],
    },
    fourthSection: {
      features: [
        {
          heading: 'Alle Features',
          text:
            'Egal welchen Plan Sie verwenden. Sie können immer auf alle Features von userdocks zugreifen. Jetzt und in Zukunft.',
        },
        {
          heading: 'Email Login',
          text: 'Registriere neue Nutzer mit Email und Passwort.',
        },
        {
          heading: 'Social Login',
          text:
            'Registriere neue Nutzer über Google, Facebook, Twitter, Github.',
        },
        {
          heading: 'Login Only Apps',
          text:
            'Apps wo man sich nicht registrieren kann. Nutzer müssen eingeladen werden.',
        },
        {
          heading: 'User Rollen',
          text:
            'Erstelle und vergebe unlimitiert User Rollen um Nutzern Teile Ihrer App freizugeben.',
        },
        {
          heading: 'Account-Linking',
          text:
            'Wir erstellen keine doppelten Accounts. Alle Social Logins sind einem Profil zugewiesen.',
        },
        {
          heading: 'Userverwaltung',
          text:
            'Verwalten Sie alle Nutzer direkt über das userdocks Dashboard. Sperre oder Lösche Nutzer.',
        },
        {
          heading: 'API',
          text:
            'Alle Funktionen vom Dashboard sind auch über eine REST API verfügbar.',
        },
        {
          heading: 'Zahlungen',
          text:
            'userdocks intigriert stripe um schnell ein Zahlungssystem zu eurer App hinzuzufügen.',
        },
        {
          buttons: [
            {
              text: 'Dokumentation',
              title: 'Lesen Sie die userdocks Dokumentation',
            },
            {
              text: 'Kostenlos testen',
              title: 'Jetzt userdocks kostenlos testen',
            },
          ],
        },
      ],
    },
    fifthSection: {
      heading: {
        1: 'Identitäts- & Zugriffsverwaltung',
        2: 'von Developer für Developer!',
      },
      text:
        'Wir, bei userdocks, wissen ganz genau worauf es ankommt, wenn man eine IAM Lösung in eine Web-App integriert. Als Developer, wissen wir wie wichtig eine gute Dokumentation ist.',
      text2:
        'Jeder von uns hat zu oft Authentifizierungs-, Autorisierungs- und Benutzerverwaltungslösungen für mehrere Projekte erstellt. Aus diesem Grund haben wir userdocks erstellt, eine Lösung, die für die meisten Projekte geeignet ist und auch eine Zahlungslösung integriert, die in fast jedem Projekt benötigt wird.',
      button: {
        text: 'Dokumentation',
        title: 'Lesen Sie die userdocks Dokumentation',
      },
      button2: {
        text: 'Jetzt kostenlos testen',
        title: 'Jetzt userdocks kostenlos ausprobieren',
        sub: 'Keine Kreditkarte notwendig!',
      },
    },
    sixthSection: i18n.de,
  },
  en: {
    form: {
      email: {
        label: 'Email *',
        placeholder: rand === 0 ? 'john.doe@email.com' : 'jane.doe@email.com',
        error: 'You need to provide a valid email address.',
      },
      button: {
        text: 'Try for free',
        sub: 'No credit card required!',
      },
    },
    landingPage: {
      heading: { 1: 'Identity & Access Management', 2: 'for your Web Apps!' },
      subHeading: {
        1: 'Integrate authetication and authorization',
        2: 'into your web app or static website within minutes.',
      },
    },
    firstSection: {
      heading: {
        1: 'Built a login for',
        2: 'your web app within minutes!',
      },
      text: [
        'Add the userdocks SDK and a two buttons with specific attributes to your web app and you have the moste basic login flow implemented.',
      ],
    },
    secondSection: {
      heading: {
        1: 'A few code lines later, ...',
      },
      text: [
        '... you allow your users to sign in or up with an email address or with a social login via GitHub, Google, Twitter, or Facebook.',
        '... you allow users to stay logged in over sessions for a specified duration you can set in the userdocks dashboard.',
        '... you can always verify the identity of your users via email. Users to your applications always have to opt-in to your terms and privacy policy.',
        '... if your users forget their password they can easily reset it at any time via the userdocks login page.',
      ],
    },
    thirdSection: {
      heading: {
        1: 'Start adding authentication & authorization',
        2: 'to your web apps.',
      },
      button: {
        text: 'Try now for free',
        title: 'Try userdocks now for free',
      },
      text: ['No credit card required!'],
    },
    fourthSection: {
      features: [
        {
          heading: 'All features',
          text:
            'No matter what plan you use. You can always access all features of userdocks. Now and in future.',
        },
        {
          heading: 'Email sign in',
          text: 'Register new users with email and password.',
        },
        {
          heading: 'Social sign in',
          text: 'Register new users via Google, Facebook, Twitter, and Github.',
        },
        {
          heading: 'Invite only apps',
          text:
            'Create applications where users can not register. New users can only be invited.',
        },
        {
          heading: 'User roles',
          text:
            'Create and assign unlimited user roles to give users acces to parts of your app.',
        },
        {
          heading: 'Account linking',
          text:
            'We do not create duplicate accounts. All social logins are linked to one profile.',
        },
        {
          heading: 'User management',
          text:
            'Manage all users directly on the userdocks dashboard. Block or delete users.',
        },
        {
          heading: 'API',
          text:
            'All functions from the dashboard are also available via our REST API.',
        },
        {
          heading: 'Payments',
          text:
            'userdocks integrates stripe to quickly add a payment system to your app.',
        },
        {
          buttons: [
            {
              text: 'Read the docs',
              title: 'Read the userdocks documentation',
            },
            {
              text: 'Try for free',
              title: 'Try userdocks for free',
            },
          ],
        },
      ],
    },
    fifthSection: {
      heading: {
        1: 'Identity & Access Management',
        2: 'created by Developers for Developers!',
      },
      text:
        'At userdocks we know exactly what is needed to integrate IAM into a web application. We are all developers here and know how important a good documentation is.',
      text2:
        'Everyone of us created authentication, authorization and user management solutions for multiple projects far too often. Thats why we created userdocks, one solution that fits most projects and also integrates a payment solution, which is needed in almost every project.',
      button: {
        text: 'Read the docs',
        title: 'Read the userdocks documentation',
      },
      button2: {
        text: 'Try now for free',
        title: 'Try userdocks now for free',
        sub: 'No credit card required!',
      },
    },
    sixthSection: i18n.en,
  },
};

export default data;
