"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deepMerge = void 0;

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

/**
 * Parts of David Walsh
 * https://davidwalsh.name/javascript-deep-merge
 */
var isMergeableObject = function isMergeableObject(val) {
  var nonNullObject = val && _typeof(val) === "object";
  return nonNullObject && Object.prototype.toString.call(val) !== "[object RegExp]" && Object.prototype.toString.call(val) !== "[object Date]";
};

var emptyTarget = function emptyTarget(val) {
  return Array.isArray(val) ? [] : {};
};

var cloneIfNecessary = function cloneIfNecessary(value, optionsArgument) {
  var clone = optionsArgument && optionsArgument.clone === true;
  return clone && isMergeableObject(value) ? deepMerge(emptyTarget(value), value, optionsArgument) : value;
};

var mergeObject = function mergeObject(target, source, optionsArgument) {
  var destination = {};

  if (isMergeableObject(target)) {
    Object.keys(target).forEach(function (key) {
      destination[key] = cloneIfNecessary(target[key], optionsArgument);
    });
  }

  Object.keys(source).forEach(function (key) {
    if (!isMergeableObject(source[key]) || !target[key]) {
      destination[key] = cloneIfNecessary(source[key], optionsArgument);
    } else {
      destination[key] = deepMerge(target[key], source[key], optionsArgument);
    }
  });
  return destination;
};

var deepMerge = function deepMerge(target, source, optionsArgument) {
  var array = Array.isArray(source);

  if (array) {
    return cloneIfNecessary(source, optionsArgument);
  } else {
    return mergeObject(target, source, optionsArgument);
  }
};

exports.deepMerge = deepMerge;