import React, { FunctionComponent } from 'react';
import { useI18n } from 'react-i18n-kit';

import userdocks from '@userdocks/web-client-sdk';
import {
  StyledLandingPage,
  StyledRow,
  StyledFlex,
  StyledDiv,
  StyledCard,
  StyledCardBody,
  StyledFlexGrid,
  StyledFlexGridCol,
  StyledImgWrapper,
} from '../../../../styles';
import { Button } from '../../../../components/Button';
import Container from '../../../../components/Container';
import Accordion from '../../../../components/Accordion';
import Text from '../../../../components/Text';
import Helmet from '../../../../components/Helmet';
import data from '../../config/content/helmet';
import {
  StyledAboveFold,
  StyledHeading,
  StyledSubHeadingP,
} from './StyledLandingPage';
import i18nLandingPage from './i18nLandingPage';
import { useLanguage } from '../../../../utils/language';
import Pricing from '../../../../views/Pricing';
import Features from '../../../../views/Features';

// eslint-disable-next-line
// @ts-ignore
import codeExample from '../../assets/img/code-example.png';
// eslint-disable-next-line
// @ts-ignore
import loginExample from '../../assets/img/login-example.png';
import { userdocksConfig } from '../../../../config/userdocks';

const LandingPage: FunctionComponent = () => {
  const { language } = useLanguage();
  const { i18n } = useI18n(i18nLandingPage, {
    lang: language,
  });

  return (
    <StyledLandingPage>
      <Helmet data={data} page="landing" />
      <StyledAboveFold>
        <StyledFlex ta="center" fd="column" width="65%">
          <StyledHeading>
            <span>{i18n.landingPage.heading['1']}</span>
            <br />
            <span>{i18n.landingPage.heading['2']}</span>
          </StyledHeading>
          <StyledSubHeadingP>
            {i18n.landingPage.subHeading['1']}
            <br />
            {i18n.landingPage.subHeading['2']}
          </StyledSubHeadingP>
          <StyledFlex ta="center" fd="column" width="45%" p="0.5rem 2rem">
            <Button
              type="button"
              id="submit-1"
              cta
              onClick={async () => {
                await userdocks.initialize(userdocksConfig);

                return userdocks.redirectTo({ type: 'signUp' });
              }}
            >
              {i18n.form.button.text}
            </Button>
            <Text mt="0" sub>
              {i18n.form.button.sub}
            </Text>
          </StyledFlex>
        </StyledFlex>
      </StyledAboveFold>
      <StyledDiv dark>
        <Container slim>
          <StyledRow>
            <StyledFlex jc="center">
              <Text light useType="h2" ta="center">
                <span>{i18n.firstSection.heading['1']}</span>
                <br />
                <span>{i18n.firstSection.heading['2']}</span>
              </Text>
            </StyledFlex>
          </StyledRow>
          <StyledRow>
            <StyledFlex fd="column">
              {i18n.firstSection.text.map((item: string) => (
                <Text light ta="center">
                  {item}
                </Text>
              ))}
            </StyledFlex>
          </StyledRow>
        </Container>
        <Container>
          <StyledRow>
            <StyledFlex p="2rem">
              <StyledImgWrapper>
                <img
                  src={codeExample}
                  alt="userdocks code integration example"
                />
              </StyledImgWrapper>
            </StyledFlex>
          </StyledRow>
        </Container>
      </StyledDiv>
      <StyledDiv>
        <Container slim>
          <StyledRow>
            <StyledFlex jc="center">
              <Text useType="h2" ta="center">
                <span>{i18n.secondSection.heading['1']}</span>
              </Text>
            </StyledFlex>
          </StyledRow>
        </Container>
        <Container slim>
          <StyledFlex>
            <StyledImgWrapper noColor width="503px">
              <img src={loginExample} alt="userdocks login page example" />
            </StyledImgWrapper>
          </StyledFlex>
        </Container>
        <Container slim>
          <StyledRow>
            <StyledFlexGrid noc={2}>
              {i18n.secondSection.text.map((item: string) => (
                <StyledFlexGridCol noc={2}>
                  <StyledCard height="100%">
                    <StyledCardBody>
                      <Text ta="start">{item}</Text>
                    </StyledCardBody>
                  </StyledCard>
                </StyledFlexGridCol>
              ))}
            </StyledFlexGrid>
          </StyledRow>
        </Container>
      </StyledDiv>
      <StyledDiv dark>
        <Container slim>
          <StyledRow>
            <StyledFlex jc="center">
              <Text light useType="h2" ta="center">
                <span>{i18n.thirdSection.heading['1']}</span>
                <br />
                <span>{i18n.thirdSection.heading['2']}</span>
              </Text>
            </StyledFlex>
          </StyledRow>
          <StyledRow>
            <StyledFlex ta="center" fd="column" width="45%" p="0.5rem 2rem">
              <Button
                type="button"
                id="submit"
                cta
                onClick={async () => {
                  await userdocks.initialize(userdocksConfig);

                  return userdocks.redirectTo({ type: 'signUp' });
                }}
              >
                {i18n.thirdSection.button.text}
              </Button>
              <Text mt="0" light sub>
                {i18n.thirdSection.text[0]}
              </Text>
            </StyledFlex>
          </StyledRow>
        </Container>
      </StyledDiv>
      <StyledDiv>
        <Pricing noMarginTop noHelmet />
        <Features noMarginTop noHelmet />
      </StyledDiv>
      <StyledDiv dark>
        <Container slim>
          <StyledRow>
            <StyledFlex jc="center">
              <Text light useType="h2" ta="center">
                <span>{i18n.fifthSection.heading['1']}</span>
                <br />
                <span>{i18n.fifthSection.heading['2']}</span>
              </Text>
            </StyledFlex>
          </StyledRow>
          <StyledRow>
            <StyledFlex ta="center" fd="column" width="45%" p="0.5rem 2rem">
              <Text ta="start" light>
                {i18n.fifthSection.text}
              </Text>
              <Button
                type="button"
                id="submit"
                ctaOutline
                onClick={() => {
                  window.location.href = 'https://docs.userdocks.com/';
                }}
              >
                {i18n.fifthSection.button.text}
              </Button>
            </StyledFlex>
          </StyledRow>
          <StyledRow>
            <StyledFlex ta="center" fd="column" width="45%" p="0.5rem 2rem">
              <Text ta="start" light>
                {i18n.fifthSection.text2}
              </Text>
              <Button
                type="button"
                id="submit"
                cta
                onClick={async () => {
                  await userdocks.initialize(userdocksConfig);

                  return userdocks.redirectTo({ type: 'signUp' });
                }}
              >
                {i18n.fifthSection.button2.text}
              </Button>
            </StyledFlex>
          </StyledRow>
        </Container>
      </StyledDiv>
      <StyledDiv>
        <Container slim>
          <StyledRow>
            <StyledCard>
              <StyledCardBody>
                <Accordion
                  title={i18n.sixthSection.faqTitle}
                  list={i18n.sixthSection.list}
                  options={{
                    openItem: 'help1',
                    alwaysOpen: true,
                  }}
                />
              </StyledCardBody>
            </StyledCard>
          </StyledRow>
        </Container>
      </StyledDiv>
    </StyledLandingPage>
  );
};
export default LandingPage;
