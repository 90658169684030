import { rgba } from 'polished';

import { styled, device } from '../../../../theme';
import responsiveFont, {
  responsiveLine,
} from '../../../../utils/responsiveFont';
import Container from '../../../../components/Container';

export const StyledAboveFold = styled.div`
  height: calc(
    var(--vh, 1vh) * 100 - (${({ theme }) => theme.metrics.headerHeight} + 3rem)
  );
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 2rem;
  ${Container} {
    padding-bottom: 0;
  }
`;

export const StyledHeading = styled.h1`
  ${responsiveFont({
    maxFontSize: 48,
    minFontSize: 28,
  })}
  & > span {
    ${responsiveLine({
      maxFontSize: 48,
      minFontSize: 28,
    })}
    /* background-image: linear-gradient(
      transparent 0%,
      transparent calc(39% + 1rem),
      ${({ theme }) => theme.colors.brandSecondary} calc(50% + 0rem),
      ${({ theme }) => theme.colors.brandSecondary} 100%
    ); */
  }
  font-weight: bold;
  @media ${device.tablet} {
    & > span {
      ${responsiveLine({
        maxFontSize: 48,
        minFontSize: 28,
      })}
      /* background-image: linear-gradient(
        transparent 0%,
        transparent calc(54% + 1rem),
        ${({ theme }) => theme.colors.brandSecondary} calc(50% + 1rem),
        ${({ theme }) => theme.colors.brandSecondary} 100%
      ); */
    }
  }
`;

export const StyledSubHeadingP = styled.p`
  ${responsiveFont({
    maxFontSize: 24,
    minFontSize: 18,
  })}
  line-height: 1.4;
`;
