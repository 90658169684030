import { apiRoutes } from '../../../../utils/url';

const header = {
  de: {
    login: {
      id: 'userdocks-sign-in-button',
      redirectUri: apiRoutes.redirect_uri,
      clientId: 'f0af4569-4d5d-4c20-af95-5a80c74e30a6',
    },
    signUp: {
      id: 'userdocks-sign-up-button',
      redirectUri: apiRoutes.redirect_uri,
      clientId: 'f0af4569-4d5d-4c20-af95-5a80c74e30a6',
    },
    links: [],
  },
  en: {
    login: {
      id: 'userdocks-sign-in-button',
      redirectUri: apiRoutes.redirect_uri,
      clientId: 'f0af4569-4d5d-4c20-af95-5a80c74e30a6',
    },
    signUp: {
      id: 'userdocks-sign-up-button',
      redirectUri: apiRoutes.redirect_uri,
      clientId: 'f0af4569-4d5d-4c20-af95-5a80c74e30a6',
    },
    links: [],
  },
};

export default header;
