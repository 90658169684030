import React, { FunctionComponent } from 'react';
import { useI18n } from 'react-i18n-kit';

import Container from '../../components/Container';
import Helmet from '../../components/Helmet';
import Text from '../../components/Text';
import { RouterLink, Link } from '../../components/Link';
import data from './i18nTerms';
import { useLanguage } from '../../utils/language';
import { StyledCard, StyledCardBody } from '../../styles';

export interface I18n {
  i18n: typeof data['de'];
}

const Terms: FunctionComponent = () => {
  const { language } = useLanguage();
  const { i18n }: I18n = useI18n(data, {
    lang: language,
  });

  return (
    <Container mt="calc(90px + 1rem)" slim>
      <StyledCard>
        <StyledCardBody>
          <Helmet page="terms" />

          <Text useType="h1">{i18n.terms.heading}</Text>

          <Text useType="h3">{i18n.terms['1'].heading}</Text>

          {i18n.terms['1'].text.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          <Text useType="h3">{i18n.terms['2'].heading}</Text>

          {i18n.terms['2'].text.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          <ul>
            {i18n.terms['2'].link.map((item: any) => {
              if (item.extern) {
                return (
                  <li>
                    <Link
                      key={item.text.split(' ').join('-')}
                      href={item.pathname}
                    >
                      {item.text}
                    </Link>
                  </li>
                );
              }

              return (
                <li>
                  <RouterLink
                    key={item.text.split(' ').join('-')}
                    to={item.pathname}
                  >
                    {item.text}
                  </RouterLink>
                </li>
              );
            })}
          </ul>

          <Text useType="h3">{i18n.terms['3'].heading}</Text>

          {i18n.terms['3'].text.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          <Text useType="h3">{i18n.terms['4'].heading}</Text>

          {i18n.terms['4'].text.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          <Text useType="h3">{i18n.terms['5'].heading}</Text>

          {i18n.terms['5'].text.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          <Text useType="h3">{i18n.terms['6'].heading}</Text>

          {i18n.terms['6'].text.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          <Text useType="h3">{i18n.terms['7'].heading}</Text>

          {i18n.terms['7'].text.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          <ul>
            {i18n.terms['7'].link.map((item: any) => {
              if (item.extern) {
                return (
                  <li>
                    <Link
                      key={item.text.split(' ').join('-')}
                      href={item.pathname}
                    >
                      {item.text}
                    </Link>
                  </li>
                );
              }

              return (
                <li>
                  <RouterLink
                    key={item.text.split(' ').join('-')}
                    to={item.pathname}
                  >
                    {item.text}
                  </RouterLink>
                </li>
              );
            })}
          </ul>

          <Text useType="h3">{i18n.terms['8'].heading}</Text>

          {i18n.terms['8'].text.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          <ul>
            {i18n.terms['8'].link.map((item: any) => {
              if (item.extern) {
                return (
                  <li>
                    <Link
                      key={item.text.split(' ').join('-')}
                      href={item.pathname}
                    >
                      {item.text}
                    </Link>
                  </li>
                );
              }

              return (
                <li>
                  <RouterLink
                    key={item.text.split(' ').join('-')}
                    to={item.pathname}
                  >
                    {item.text}
                  </RouterLink>
                </li>
              );
            })}
          </ul>

          <Text useType="h3">{i18n.terms['9'].heading}</Text>

          {i18n.terms['9'].text.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          <Text useType="h3">{i18n.terms['10'].heading}</Text>

          {i18n.terms['10'].text.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          <Text useType="h3">{i18n.terms['11'].heading}</Text>

          {i18n.terms['11'].text.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}

          {i18n.terms.date.text.map((item: string) => (
            <Text key={item.split(' ').join('-')}>{item}</Text>
          ))}
        </StyledCardBody>
      </StyledCard>
    </Container>
  );
};

export default Terms;
