import React from 'react';

import { Link } from '../../components/Link';
import {
  mailToLinkContact,
  baseDocsLink,
  baseAppLink,
  baseLandingLink,
  pricingUrl,
} from '../../utils/url';
import lang from '../../utils/language/getLanguage';

const i18n = {
  de: {
    title: 'Help Center',
    faqTitle: 'FAQ',
    text: (
      <>
        Bitte schauen Sie ob Ihre Frage in den untenstehenden FAQs beantwortet
        wird. Sollte Ihre Frage unbeantwortet bleiben erreichen Sie uns unter
        <Link noMarginRight href={mailToLinkContact}>
          hello@userdocks.com
        </Link>
        .
      </>
    ),
    list: [
      {
        key: 'help1',
        title: (
          <>
            Ist <i>userdocks</i> kostenlos? Ist es kostenlos für Non-Profit
            Organisationen?
          </>
        ),
        text: (
          <>
            Sie können <strong>userdocks</strong> während der Entwicklung lokal
            auf ihrem Gerät jederzeit kostenlos verwenden. Sobald Sie Ihre App
            live verwenden ist die Nutzung nicht mehr kostenlos. Werfen Sie
            einen Blick auf unsere
            <Link
              noMarginRight
              href={pricingUrl(lang)}
              target="_blank"
              rel="noopener noreferrer"
            >
              Preise
            </Link>
            .
            <br />
            <br />
            Sie benötigen <strong>userdocks</strong> für Ihre "Non-Profit
            Organisiation"/"Open Source Projekt das nicht auf Gewinn aus ist"?
            Dann kontaktieren Sie uns doch unter
            <Link href={mailToLinkContact}>hello@userdocks.com</Link>
            und wir können mit Ihnen einen gesonderten Tarif ausmachen.
          </>
        ),
      },
      {
        key: 'help2',
        title: (
          <>
            Was kann ich mit <i>userdocks</i> machen?
          </>
        ),
        text: (
          <>
            <strong>userdocks</strong> ist eine{' '}
            <strong>Identity und Access Management Plattform</strong> die Ihnen
            dabei hilft den Zugriff zu Ihrer Web App zu regeln und Ihnen die
            Möglichkeit bietet die Berechtigungen für Ihre User leichter
            handzuhaben.
            <br />
            <br />
            Sie können Ihre User mittels Dashboard auf
            <Link href={baseAppLink} target="_blank" rel="noopener noreferrer">
              https://app.userdocks.com
            </Link>
            verwalten oder mittels einer REST API direkt von Ihrem Server.
            <br />
            <br />
            <strong>userdocks</strong> kann in jeder Web App innerhalb von
            wenigen Minuten implementiert werden. Hierfür müssen Sie das{' '}
            <strong>userdocks identity script</strong> einbinden und einen
            Button mit drei Attributen auf Ihrer Seite einbinden, nachdem Sie
            Ihre Web App bei <strong>userdocks</strong> registriert haben.
          </>
        ),
      },
      {
        key: 'help3',
        title: 'Wo ist die Dokumentation?',
        text: (
          <>
            <Link
              noMargin
              href={baseDocsLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Hier ist der Link zur Dokumentation.
            </Link>
            <br />
            <br />
            Sie finden diesen Link aber auch in der Haupt-Navigation in unserer
            <Link href={baseAppLink} target="_blank" rel="noopener noreferrer">
              App
            </Link>
            und auf unserer
            <Link
              noMarginRight
              href={baseLandingLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Landing Page
            </Link>
            .
          </>
        ),
      },
      {
        key: 'help4',
        title: (
          <>
            Warum sollten Sie <i>userdocks</i> Ihre User anvertrauen?
          </>
        ),
        text: (
          <>
            Wir bei <i>userdocks</i> haben Datensicherheit zu unserer obersten
            Priorität gemacht. Wir behandeln alle Daten mit der gleichen
            Priorität, unabhängug davon ob diese einen Personenbezug haben oder
            nicht.
            <br />
            <br />
            Passwörter werden niemals in Klartext gespeichert. Zum hashen der
            Passwörter verwenden wir den
            <Link
              noMarginRight
              href="https://en.wikipedia.org/wiki/Bcrypt"
              target="_blank"
              rel="noopener noreferrer"
            >
              bcrypt Algorithmus
            </Link>
            . Dieser ist darauf konzipiert um Brute-Force-Angriffen vorzubeugen.
            <br />
            <br />
            Um gegen Brute-Force-Angriffe auf Secrets vorbeugen werden Secrets
            niemals nach einem bestimmten Schema generiert. Das erstellen
            unserer 512 bit Secrets überlassen wir zu 100% dem Zufall. Jedes
            unserer Secrets hat mindestens 64 Zeichen das enspricht 512 bit und
            somit höchsten Industriestandards.
          </>
        ),
      },
      {
        key: 'help5',
        title: (
          <>
            Was passiert mit Ihren Userdaten wenn Sie aufhören <i>userdocks</i>{' '}
            zu verwenden?
          </>
        ),
        text: (
          <>
            Alle Userdaten die wir für Sie anlegen, verwalten und verarbeiten
            gehören zu jedem Zeitpunkt zu 100% Ihnen. Auch wenn Sie sich
            entscheiden <i>userdocks</i> nicht mehr weiter zu verwenden. Melden
            Sie sich unter
            <Link href={mailToLinkContact}>hello@userdocks.com</Link> und wir
            werden Ihnen Ihre Daten zum export zur Verfügung stellen.
          </>
        ),
      },
      {
        key: 'help6',
        title: (
          <>
            Wie lange kann ich <i>userdocks</i> kostenlos verwenden?
          </>
        ),
        text: (
          <>
            Sie können <i>userdocks</i> lokal auf Ihrem Gerät während der
            Entwicklung so lange kostenlos verwenden und testen wie Sie möchten.
            Erst sobald Sie Ihre Applikation live schalten fallen kosten für
            Ihre Applikation an.
            <br />
            <br />
            <Link
              noMargin
              href={pricingUrl(lang)}
              target="_blank"
              rel="noopener noreferrer"
            >
              Hier finden Sie Informationen wie sich unsere Preise
              zusammensetzen.
            </Link>
          </>
        ),
      },
      {
        key: 'help7',
        title: (
          <>
            Auf welchen Servern läuft <i>userdocks</i>?
          </>
        ),
        text: (
          <>
            Der Client ist auf unseren Servern eingerichtet entweder auf AWS
            (Amazon Web Services) oder DO (Digital Ocean). Grundsätzlich läuft
            jede Applikation von <i>userdocks</i> in unserer Public Cloud
            (Geteilte Ressourcen - Multi-Tenant). Für Enterprice Kunden können
            wir <i>userdocks</i> auch auf einem dedizierten Server der nur von
            einem Kunden verwendet wird (Dedizierte Ressourcen - Single-Tenant).
            Melden Sie sich unter
            <Link href={mailToLinkContact}>hello@userdocks.com</Link> wenn Sie
            einen dedizierten Host bentigen.
          </>
        ),
      },
      {
        key: 'help8',
        title: <>Wie kann mein Abo ändern?</>,
        text: (
          <>
            Ihr Abo ist abhängig von der Nutzung. Es wird jeden Monat nach
            Verbrauchten Ressourcen abgerechnet. Sie erhalten von uns eine
            Rechnung. Werfen Sie einen Blick auf unsere
            <Link
              noMarginRight
              href={pricingUrl(lang)}
              target="_blank"
              rel="noopener noreferrer"
            >
              Preise und Abovarianten
            </Link>{' '}
            um mehr zu erfahren.
          </>
        ),
      },
      {
        key: 'help9',
        title: <>Kann ich mein Abo beenden?</>,
        text: (
          <>
            Sie können Ihr Abo zwei Wochen vor Anfang der nächsten
            Rechnungsperiode beenden. Die Rechnungsperiode beginnt bei uns immer
            am 01. des Monats unabhängig davon ob ein Monat 28, 29, 30 oder 31
            Tage hat. Sollten Sie also am 14. Februar dass Sie Ihr Abo beenden
            wollen. So ist der frühest mögliche Termin der 01. März. Sie können
            aber auch den Beginn einer jeden weiteren Rechnungsperiode als
            Kündigungstermin angeben. Ihre Applikation wird von unserem Team im
            Laufe des Tages am ersten des Monats deaktiviert.
          </>
        ),
      },
    ],
  },
  en: {
    title: 'Help Center',
    faqTitle: 'FAQ',
    text: (
      <>
        Please read all the questions in the frequently asked questions sections
        to see if your question is answered. If your question stays unanswered
        do not hesitate to contact us at
        <Link noMarginRight href={mailToLinkContact}>
          hello@userdocks.com
        </Link>
        .
      </>
    ),
    list: [
      {
        key: 'help1',
        title: (
          <>
            Can I use <i>userdocks</i> for free? Is this product free to use for
            non profit organizations?
          </>
        ),
        text: (
          <>
            As long as you are developing your application on your local machine
            you can use <strong>userdocks</strong> for free. As soon as your app
            is live you will have to pay. Take a look at our{' '}
            <Link
              noMarginRight
              href={pricingUrl(lang)}
              target="_blank"
              rel="noopener noreferrer"
            >
              pricing section
            </Link>
            .
            <br />
            <br />
            You need <strong>userdocks</strong> for your{' '}
            <strong>
              non profit organization/open source project with no desire for
              profit
            </strong>
            ? Please contact us at
            <Link href={mailToLinkContact}>hello@userdocks.com</Link> so we can
            make a speacial settlement.
          </>
        ),
      },
      {
        key: 'help2',
        title: (
          <>
            What can I do with <i>userdocks</i>?
          </>
        ),
        text: (
          <>
            <strong>userdocks</strong> is an{' '}
            <strong>Identity und Access Management Plattform</strong>
            that will help you to manage the access to your web app and will
            also help you to easily manage the access rights for your users.
            <br />
            <br />
            You can manage your users via our dashboard at
            <Link href={baseAppLink} target="_blank" rel="noopener noreferrer">
              https://app.userdocks.com
            </Link>
            or via an REST API directly from your own server.
            <br />
            <br />
            <strong>userdocks</strong> can be implemented within a few minutes
            into any web app. For this you have to embed the{' '}
            <strong>userdocks identity script</strong> and a button with three
            specified attributes, after you have registered your web app at{' '}
            <strong>userdocks</strong>
          </>
        ),
      },
      {
        key: 'help3',
        title: 'Where is the documentation?',
        text: (
          <>
            <Link
              noMargin
              href={baseDocsLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Here is the linkt to the documentation.
            </Link>
            <br />
            <br />
            You can find this link in the top navigation of our
            <Link href={baseAppLink} target="_blank" rel="noopener noreferrer">
              app
            </Link>
            and on our
            <Link
              noMarginRight
              href={baseLandingLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              landing page
            </Link>
            .
          </>
        ),
      },
      {
        key: 'help4',
        title: (
          <>
            Why should you trust <i>userdocks</i> with your user data?
          </>
        ),
        text: (
          <>
            We at <i>userdocks</i> have made data security to our top priority.
            We treat all data with the same priority, regardless of whether the
            data has personal reference or not.
            <br />
            <br />
            Password are never stored in plain text. To hash our passwords we
            use the
            <Link
              noMarginRight
              href="https://en.wikipedia.org/wiki/Bcrypt"
              target="_blank"
              rel="noopener noreferrer"
            >
              bcrypt algorithm
            </Link>
            . This algorithm is designed to prevent brute force attacks.
            <br />
            <br />
            To prevent brute force attacks on secrets, they are never generated
            according to a certain scheme. The generation of our 512 bit secrets
            is 100% random. Every secret has at least 64 characters, which
            corresponds to 512 bits and thus the highest industry standards.
          </>
        ),
      },
      {
        key: 'help5',
        title: (
          <>
            What happens with your user data if you stop using <i>userdocks</i>?
          </>
        ),
        text: (
          <>
            All of the user data that we create, manage and process are 100%
            yours at all times. Even if you choose to stop using{' '}
            <i>userdocks</i>. Send us an email to{' '}
            <Link href={mailToLinkContact}>hello@userdocks.com</Link> and we
            will provide you with a way to export your user data.
          </>
        ),
      },
      {
        key: 'help6',
        title: (
          <>
            How long can I use <i>userdocks</i> for free?
          </>
        ),
        text: (
          <>
            You can use <i>userdocks</i> on your local development machine for
            as long as you are developing and testing your application. As soon
            as your application is live and online you have to pay for our
            service.
            <br />
            <br />
            <Link
              noMargin
              href={pricingUrl(lang)}
              target="_blank"
              rel="noopener noreferrer"
            >
              Here you can find more information about our pricing.
            </Link>
          </>
        ),
      },
      {
        key: 'help7',
        title: (
          <>
            On which servers does <i>userdocks</i> operate?
          </>
        ),
        text: (
          <>
            The client is set up on our servers on either AWS (Amazon Web
            Services) or DO (Digital Ocean). Basically every application on{' '}
            <i>userdocks</i> is operating on our public cloud (Shared resources
            - multi-tenant). For Enterprice customers we also allow to setup{' '}
            <i>userdocks</i> on a dedicated server (dedicated resources - single
            tenant). Send an email to
            <Link href={mailToLinkContact}>hello@userdocks.com</Link> if you
            need a dedicated host.
          </>
        ),
      },
      {
        key: 'help8',
        title: <> How can I change my subscription plan?</>,
        text: (
          <>
            Your subscription is applied by your usage. It is billed each month
            based on resources used. You will receive an invoice from us. Take a
            look at our{' '}
            <Link
              noMarginRight
              href={pricingUrl(lang)}
              target="_blank"
              rel="noopener noreferrer"
            >
              pricing and subscription section
            </Link>{' '}
            to get more information about it.
          </>
        ),
      },
      {
        key: 'help9',
        title: <>When can I cancel my subscription?</>,
        text: (
          <>
            You can cancel your subscription two weeks before the start of the
            next billing period. The billing period always starts on the 1st of
            the month regardless of whether a month is 28, 29, 30 or 31 days. If
            you cancel your subscription on February 14th. The earliest possible
            date to cancel is March 1st, but you can also choose the beginning
            of each further billing period. Your application is disabled by our
            team on the first day first of the month.
          </>
        ),
      },
    ],
  },
};

export default i18n;
