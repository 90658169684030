import React, { FunctionComponent } from 'react';
import { useI18n } from 'react-i18n-kit';
import {
  AiOutlineUsergroupAdd,
  AiOutlineLink,
  AiOutlineKey,
  AiOutlineUserAdd,
  AiOutlineMail,
  AiOutlineControl,
  AiOutlineApi,
} from 'react-icons/ai';

import userdocks from '@userdocks/web-client-sdk';
import Helmet from '../../components/Helmet';
import Container from '../../components/Container';
import { Button } from '../../components/Button';
import {
  StyledCard,
  StyledCardBody,
  StyledFlex,
  StyledRow,
  StyledFlexGrid,
  StyledFlexGridCol,
  StyledDiv,
} from '../../styles';
import Text from '../../components/Text';
import data from './i18nFeatures';
import { useLanguage } from '../../utils/language';

// eslint-disable-next-line
// @ts-ignore
import githubIconColor from '../../apps/landing/assets/img/github-icon-color.png';
// eslint-disable-next-line
// @ts-ignore
import googleIconColor from '../../apps/landing/assets/img/google-icon-color.png';
// eslint-disable-next-line
// @ts-ignore
import twitterIconColor from '../../apps/landing/assets/img/twitter-icon-color.png';
// eslint-disable-next-line
// @ts-ignore
import facebookIconColor from '../../apps/landing/assets/img/facebook-icon-color.png';
// eslint-disable-next-line
// @ts-ignore
import stripeIconColor from '../../apps/landing/assets/img/stripe-icon-color.png';
import { userdocksConfig } from '../../config/userdocks';

export interface I18n {
  i18n: typeof data['de'];
}

const SocialIcons = () => (
  <>
    <img height="30px" src={githubIconColor} alt="github logo" />
    <img height="45px" src={googleIconColor} alt="google logo" />
    <img
      height="30px"
      style={{ marginRight: '0.55rem' }}
      src={twitterIconColor}
      alt="twitter logo"
    />
    <img height="30px" src={facebookIconColor} alt="facebook logo" />
  </>
);

const PaymentIcons = () => (
  <>
    <img height="45px" src={stripeIconColor} alt="stripe logo" />
  </>
);

const AcountLinkingIcons = () => (
  <>
    <AiOutlineUsergroupAdd size={30} />
    <AiOutlineLink size={30} />
  </>
);

const Features: FunctionComponent<{
  noMarginTop?: boolean;
  noHelmet?: boolean;
}> = ({ noMarginTop, noHelmet }) => {
  const { language } = useLanguage();
  const { i18n }: I18n = useI18n(data, {
    lang: language,
  });

  return (
    <>
      <StyledDiv>
        {!noHelmet && <Helmet page="features" />}
        <Container mt={noMarginTop ? '' : 'calc(90px + 1rem)'} slim>
          <StyledRow>
            <StyledFlex jc="center">
              <Text useType="h2" ta="center" id="features">
                <span>{i18n.features.features[0].heading}</span>
              </Text>
            </StyledFlex>
          </StyledRow>
          <StyledRow>
            <StyledFlex jc="center">
              <Text ta="center">{i18n.features.features[0].text}</Text>
            </StyledFlex>
          </StyledRow>
        </Container>
        <Container medium>
          <StyledFlexGrid>
            {i18n.features.features.map((item: any, i: number) => {
              if (i === 0) return null;

              let icon: any = null;

              switch (i) {
                case 1:
                  icon = <AiOutlineMail size={30} />;
                  break;
                case 2:
                  icon = <SocialIcons />;
                  break;
                case 3:
                  icon = <AiOutlineUserAdd size={30} />;
                  break;
                case 4:
                  icon = <AiOutlineKey size={30} />;
                  break;
                case 5:
                  icon = <AcountLinkingIcons />;
                  break;
                case 6:
                  icon = <AiOutlineControl size={30} />;
                  break;
                case 7:
                  icon = <AiOutlineApi size={30} />;
                  break;
                default:
                case 8:
                  icon = <PaymentIcons />;
                  break;
              }

              return (
                <StyledFlexGridCol noc={3}>
                  <StyledCard height="100%">
                    <StyledCardBody>
                      <Text useType="h3" ta="center">
                        {item.heading}
                      </Text>
                      <StyledFlex
                        mb={i === 2 || i === 8 ? '-0.55rem' : ''}
                        mt={i === 2 || i === 8 ? '0.65rem' : '1rem'}
                      >
                        {icon}
                      </StyledFlex>
                      <Text ta="start">{item.text}</Text>
                    </StyledCardBody>
                  </StyledCard>
                </StyledFlexGridCol>
              );
            })}
          </StyledFlexGrid>
          <StyledFlex>
            <StyledFlex
              bm
              height="100%"
              jc="center"
              ai="center"
              fd="column"
              p="1rem"
            >
              <Button
                ml="1rem"
                mb="1rem"
                mr="1rem"
                type="button"
                id="submit"
                ctaOutline
                onClick={() => {
                  window.location.href = 'https://docs.userdocks.com/';
                }}
              >
                {i18n.features.buttons[0].text}
              </Button>
              <Button
                ml="1rem"
                mb="1rem"
                mr="1rem"
                type="button"
                id="submit"
                cta
                onClick={async () => {
                  await userdocks.initialize(userdocksConfig);

                  return userdocks?.redirectTo({ type: 'signUp' });
                }}
              >
                {i18n.features.buttons[1].text}
              </Button>
            </StyledFlex>
          </StyledFlex>
        </Container>
      </StyledDiv>
    </>
  );
};

export default Features;
