import { contactEmail } from '../../utils/contactEmail';

const i18n = {
  de: {
    privacyPolicy: {
      heading: 'Datenschutzerklärung',
      text: [
        'Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir (in weiterer folge auch Website-Betreiber genannt) verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003) nach österreichischen und europäischen Recht. In diesen Datenschutzinformationen informieren wir Sie (in weiterer folge auch Website-Benutzer genannt) über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Website und/oder Webapplikation (in weiterfolge Website genannt).',
        'Beim Besuch unserer Webseite wird Ihre IP-Adresse, Beginn und Ende der Sitzung für die Dauer dieser Sitzung erfasst. Dies ist technisch bedingt und stellt damit ein berechtigtes Interesse iSv Art 6 Abs 1 lit f DSGVO dar. Soweit im Folgenden nichts anderes geregelt wird, werden diese Daten von uns nicht weiterverarbeitet.',
      ],
      contactWithUs: {
        heading: 'Kontakt mit uns',
        text: [
          'Wenn Sie per Formular auf der Website oder per E-Mail Kontakt mit uns aufnehmen, werden Ihre angegebenen Daten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen sechs Monate bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.',
        ],
      },
      dataStorage: {
        heading: 'Datenspeicherung',
        text: [
          'Wir weisen darauf hin, dass zum Zweck unserer Website und zur späteren Vertragsabwicklung vom Website-Betreiber im Rahmen von Cookies die IP-Daten des Anschlussinhabers gespeichert werden.',
          'Auf unseren Webservern gibt es Weblogs, darin werden folgende Daten gesammelt und für 14 Tage gespeichert:',
        ],
        list: [
          'aufgerufene Website URL',
          'Broswer Informationen (u.a. Browser-Version, Browser-Typ)',
          'Betriebsystem des Website-Benutzers',
          'Referrer URL (die Website von der der Website-Benutzer auf unsere Seite gelangte)',
          'Hostname des Website-Benutzers',
          'IP Adresse des Website-Benutzers',
          'Datum',
          'Uhrzeit',
        ],
        text1: [
          'Darüber hinaus werden zum Zweck der Vertragsabwicklung folgende Daten auch bei uns gespeichert:',
        ],
        list1: [
          'Email-Adresse',
          'Vorname',
          'Nachname',
          'Adresse',
          'Kreditkartendaten (bei Stripe)',
        ],
        text2: [
          'Wenn Sie sich bei Userdocks mit Ihrer Email-Adresse registrieren werden die folgenden Daten beim übermitteln des Formulars gespeichert:',
        ],
        list2: ['Email-Adresse'],
        text3: [
          'Wenn Sie sich bei Userdocks über Facebook, Twitter, Github, oder Google registrieren werden die folgenden Daten beim übermitteln des Formulars gespeichert:',
        ],
        list3: [
          'Id (Ihre Id auf Facebook/Twitter/Github/Google)',
          'Email-Adresse (Ihre auf Facebook/Twitter/Github/Google verwendete Email-Adresse)',
        ],
        text4: [
          'Wenn Sie sich bei Userdocks über Facebook, Twitter, Github, oder Google registrieren wird der Access Token (Zugangscode) für Facebook, Twitter, Github, oder Google nicht dauerhaft bei uns gespeichert, das heißt userdocks verwendet diesen Zugangscode nur einmal um Ihre Identität zu verifizieren, und verwirft diesen Code danach.',
          'userdocks wird niemals im Namen von Ihrem Account etwas auf Facebook, Twitter, Github, oder Google posten/verändern.',
          'userdocks verwendet lediglich den Zugriff auf Ihr Konto um die damit Verbundene Email-Adresse und Id zu speichern.',
        ],
        text5: [
          'Wenn Sie Ihr userdocks Konto mit einem Stripe Konto verbinden speichern wir folgende Daten:',
        ],
        list4: ['Stripe-Account-Id'],
        text6: [
          'Sie können userdocks mit zusätzlichen Persönlichen Informationen.',
          'Einige Applikationen auf userdocks erlauben es möglicherweise ein detailierteres Profil zuerstellen, welche die nachstehenden Daten enthalten kann aber nicht zwingendermaßen muss:',
        ],
        list5: [
          'Anrede',
          'Andere Anrede',
          'Name',
          'Vorname',
          'Familienname',
          'Mittlerer Name',
          'Nickname',
          'Bevorzugter Username',
          'Profil',
          'Bild',
          'Website',
          'Geschlecht',
          'Geburtsdatum',
          'Zoneninfo',
          'Ort',
          'Telefonnummer',
        ],
        text7: [
          'Wenn Sie über eine Userdocks Applikation ein Stripe Abo abschließen dann werden die folgenden Daten gespeichert:',
        ],
        list6: [
          'Stripe-Kundennummer',
          'Stripe-Preis-Id',
          'Stripe-Produkt-Id',
          'Stripe-Checkout-Session-Id',
          'Stripe-Zahlungsmethode',
          'Zahlung fehlgeschlagen',
        ],
        text8: [
          'Die von Ihnen bereit gestellten Daten sind zur Vertragserfüllung bzw zur Durchführung vorvertraglicher Maßnahmen erforderlich. Ohne diese Daten können wir den Vertrag mit Ihnen nicht abschließen. Eine Datenübermittlung an Dritte erfolgt nicht, mit Ausnahme der Übermittlung der Kreditkartendaten, Email-Adresse, Name, und Adresse. Die Kredidkartendaten, Email-Adresse, Name, und Adresse werden an die abwickelnden Bankinstitute / Zahlungsdienstleister zum Zwecke der Abbuchung des Einkaufspreises, an das von uns beauftragte Transportunternehmen/Versandunternehmen zur Zustellung der Ware sowie an unseren Steuerberater zur Erfüllung unserer steuerrechtlichen Verpflichtungen. Die Email-Adresse, Vorname, Nachname, Adresse werden an einen Email Marketing Service weitergegeben, dieser Dienst hilft uns dabei unserern Newsletter zu versenden. Für die Versendung des Newsletters verwendet der Website-Betreiber den Email Marketing Service MailChimp.',
          'Desweiteren weißen wir darauf hin das zu keinem Zeitpunkt Kreditkartendaten an unsere Server übermittelt oder auf unseren Servern gespeichert werden. Zur Bearbeitung sämtlicher getätigten Zahlungen vom Website-Benutzer, verwendet der Website-Betreiber den Drittanbieter Stripe. Alle auf der Website eingetragene Kreditkartendaten werden nur über eine verschlüsselte (HTTPS) Verbindung ohne Umwege direkt an Stripe geschickt. Wir haben auf unserem Server lediglich eine ID mit der wir die Daten auf Stripe identifizieren können. Auf Stripe kann der Website-Betreiber keine vollständigen Kreditkartennummern einsehen, auschließlich die letzten 4 Ziffern. Die von Ihnen bereit gestellten Daten sind zur Vertragserfüllung bzw zur Durchführung vorvertraglicher Maßnahmen erforderlich.',
          'Nach Beendigung des Vertrages, oder durch Löschung Ihres Accounts werden die bei uns gespeicherten Daten gelöscht. Im Falle eines Vertragsabschlusses werden sämtliche Daten aus dem Vertragsverhältnis bis zum Ablauf der steuerrechtlichen Aufbewahrungsfrist (7 Jahre) gespeichert.',
          'Die Daten Name, Anschrift, gekaufte Waren und Kaufdatum werden darüber hinaus bis zum Ablauf der Produkthaftung (10 Jahre) gespeichert.',
          'Die Datenverarbeitung erfolgt auf Basis der gesetzlichen Bestimmungen des § 96 Abs 3 TKG sowie des Art 6 Abs 1 lit a (Einwilligung) und/oder lit b (notwendig zur Vertragserfüllung) der DSGVO.',
        ],
      },
      cookies: {
        heading: 'Cookies',
        text: [
          'Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an.',
          'Wir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.',
          'Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten, dass er Sie über das Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben.',
          'Bei der Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.',
          'userdocks verwendet notwendige Cookies. Notwendige Cookies sind funktionale Cookies, ohne diese Cookies funktioniert unsere Website, unsere Webapp und unser Service nicht richtig.',
          'Userdocks verwendet folgende notwendige Cookies:',
        ],
        list: [
          '"_cfduid": (Anbieter: https://cloudflare.com) hat den Zweck den Nutzer wiedererkennen um den Nutzer immer über den gleichen Routing Service zu bedienen, dies hat den Zweck den Nutzer bestmögliche optimierung der Netzwerknutzung für den Nutzer zu gewähleisten (Sessioncookie) für eine Dauer von wenigen Sekunden bis zu 24 Stunden.',
          '"_cflb": (Anbieter: https://cloudflare.com) hat den Zweck den Nutzer wiedererkennen um mögliche Angreifer automatisch zu blockieren (Sessioncookie) für eine Dauer von 30 Tagen.',
          '"_cf_bm": (Anbieter: https://cloudflare.com) hat den Zweck den Nutzer wiedererkennen um mögliche Bots automatisch zu erkennen (Sessioncookie) für eine Dauer von bis zu 30 Minuten.',
          '"userdocks.com:<UUID>:refresh_token": (Anbieter: https://userdocks.com) hat den Zweck den Nutzer wiedererkennen um den Nutzer automatisch einzuloggen (Sessioncookie) für eine Dauer von bis zu 180 Tagen.',
          '"userdocks.com:<UUID>:session_only": (Anbieter: https://userdocks.com) hat den Zweck den Nutzer wiedererkennen um zu wissen ob der Nutzer nach dem schließen des Browsers wiederekannt werden möchte oder nicht (Sessioncookie) für eine Dauer bis der Nutzer den Browser schließt.',
        ],
      },
      newsletterMailChimp: {
        heading: 'Newsletter - MailChimp',
        text: [
          'Zur Durchführung sämtlicher Newsletter verwendet der Website-Betreiber den Email Marketing Service MailChimp, der Firma "The Rocket Science Group, LLC, 675 Ponce de Leon Ave NE, Suite 5000, Atlanta, GA 30308 USA" und die darüber erzeugten Informationen/oder Informationen die benötigt werden um den Newsletter zu versenden (z.B. Email-Adresse,Vorname, Nachname) werden an ein Rechenzentrum außerhalb der Europäischen Union übermittelt.',
          'MailChimp verwendet sogennante WebBeacons (kleine Bildateien), die eine Analyse der Benutzung des Nuewsletters durch Ihre Benutzer ermöglicht. Die dadurch erzeugten Informationen werden auf den Servern des Anbieters (MailChimp) übertragen und dort gespeichert.',
          'Die Datenverarbeitung erfolgt auf Basis der gesetzlichen Bestimmungen des § 96 Abs 3 TKG sowie des Art 6 Abs 1 lit a (Einwilligung) und/oder f (berechtigtes Interesse) der DSGVO.',
          'Die Beziehung zum Webanalyseanbieter basiert auf Standardvertragsklauseln/einem Angemessenheitsbeschluss der Europäischen Komission, dem "Privacy Shield".',
          'Sie haben die Möglichkeit, über unsere Website unseren Newsletter zu abonnieren. Hierfür benötigen wir Ihre E-Mail-Adresse und ihre Erklärung, dass Sie mit dem Bezug des Newsletters einverstanden sind.',
          'Um Sie zielgerichtet mit Informationen zu versorgen, erheben und verarbeiten wir außerdem freiwillig gemachte Angaben zu Interessengebieten, Geburtstag.',
          'Sobald Sie sich für den Newsletter angemeldet haben, senden wir Ihnen ein Bestätigungs-E-Mail mit einem Link zur Bestätigung der Anmeldung.',
          'Das Abo des Newsletters können Sie jederzeit stornieren. Klicken Sie hierfür einfach den Link am Ende einer jeden Newsletter Email oder senden Sie Ihre Stornierung bitte an folgende E-Mail-Adresse: hello@userdocks.at. Wir löschen anschließend umgehend Ihre Daten im Zusammenhang mit dem Newsletter-Versand. Durch diesen Widerruf wird die Rechtmäßigkeit der aufgrund der Zustimmung bis zum Widerruf erfolgten Verarbeitung nicht berührt.',
        ],
      },
      paymentStripe: {
        heading: 'Zahlungen - Stripe',
        text: [
          'Zur Durchführung aller Zahlungen von dem Website-Benutzer an den Website-Betreiber, verwendet der Website-Betreiber den Dritt-Anbieter Stripe, der Firma "Stripe, Inc., 510 Townsend Street, San Francisco, CA 94103, USA" (oder ein abwickelndes Bankinstitut und der Kunde wird aufgefordert die Zahlungen direkt auf das Bankkonto des Website-Betreibers zu transferieren).',
          'Zum Zweck der Vertragsabwicklung werden folgende Daten auch bei uns gespeichert:',
        ],
        list: [
          'Email-Adresse',
          'Name (ev. Firma)',
          'Adresse (ev. Firmenanschrift)',
          'Kreditkartendaten',
        ],
        text1: [
          'Die von Ihnen bereit gestellten Daten sind zur Vertragserfüllung bzw zur Durchführung vorvertraglicher Maßnahmen erforderlich. Ohne diese Daten können wir den Vertrag mit Ihnen nicht abschließen. Eine Datenübermittlung an Dritte erfolgt nicht, mit Ausnahme der Übermittlung der Kreditkartendaten, Name und Adresse - an die abwickelnden Bankinstitute / Zahlungsdienstleister (Stripe) zum Zwecke der Abbuchung des Einkaufspreises, an das von uns beauftragte Transportunternehmen/Versandunternehmen zur Zustellung der Ware sowie an unseren Steuerberater zur Erfüllung unserer steuerrechtlichen Verpflichtungen',
          'Wir weißen darauf hin, dass zu keinem Zeitpunkt Kreditkartendaten an unsere Server übermittelt oder auf unseren Servern gespeichert werden. Zur Bearbeitung sämtlicher getätigten Zahlungen vom Website-Benutzer, verwendet der Website-Betreiber den Drittanbieter Stripe (oder ein abwickelndes Bankinstitut und der Website-Benutzer wird aufgefordert die Zahlung direkt auf das Bankkonto des Website-Betreibers zu transferieren). Alle auf der Website eingetragene Kreditkartendaten werden nur über eine verschlüsselte (HTTPS) Verbindung ohne umwege direkt an Stripe geschickt. Wir haben auf unserem Server lediglich eine ID mit der wir die Daten auf Stripe identifizieren können. Auf Stripe kann der Website-Betreiber keine vollständigen Kreditkartennummern einsehen, auschließlich die letzten 4 Ziffern. Die von Ihnen bereit gestellten Daten sind zur Vertragserfüllung bzw zur Durchführung vorvertraglicher Maßnahmen erforderlich.',
          'Nach Beendigung des Vertrages, oder durch Löschung Ihres Accounts werden die bei uns gespeicherten Daten gelöscht. Im Falle eines Vertragsabschlusses werden sämtliche Daten aus dem Vertragsverhältnis bis zum Ablauf der steuerrechtlichen Aufbewahrungsfrist (7 Jahre) gespeichert.',
          'Die Daten Name, Anschrift, gekaufte Waren und Kaufdatum werden darüber hinaus bis zum Ablauf der Produkthaftung (10 Jahre) gespeichert.',
          'Die Datenverarbeitung erfolgt auf Basis der gesetzlichen Bestimmungen des § 96 Abs 3 TKG sowie des Art 6 Abs 1 lit a (Einwilligung) und/oder lit b (notwendig zur Vertragserfüllung) der DSGVO.',
        ],
      },
      yourRights: {
        heading: 'Ihre Rechte',
        text: [
          `Ihnen stehen bezüglich Ihrer bei uns gespeicherten Daten grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei uns unter: ${contactEmail} oder der Datenschutzbehörde beschweren.`,
        ],
      },
      contact: {
        heading: 'Sie erreichen uns unter folgenden Kontaktdaten:',
        text: [
          'userdocks',
          'Seidlgasse 7/9',
          '1030 Wien',
          'Austria',
          'Telefon: +43 (0)677 / 630 520 38',
          `Email: ${contactEmail}`,
        ],
      },
      date: {
        text: [
          'Stand: 19.09.2020',
          'Erstellt nach dem Musterbeispiel der WKO (https://www.wko.at/service/wirtschaftsrecht-gewerberecht/muster-informationspflichten-website-datenschutzerklaerung.html)',
        ],
      },
    },
  },
  en: {
    privacyPolicy: {
      heading: 'Data Privacy Policy',
      text: [
        'The protection of your personal data is of particular concern to us. We (hereinafter also referred to as website operators) process your data exclusively on the basis of the legal provisions (GDPR, TKG 2003) according to Austrian and European law. In this data protection information we inform you (hereinafter also referred to as website users) about the most important aspects of data processing in the context of our website and / or web application (hereinafter also referred to as website).',
        'When you visit our website, your IP address, start and end of the session are recorded for the duration of this session. This is due to technical reasons and therefore represents a legitimate interest within the meaning of Art 6 (1) (f) GDPR. Unless otherwise regulated in the following, this data will not be processed by us.',
      ],
      contactWithUs: {
        heading: 'Contact Us',
        text: [
          'If you contact us using any form on the website or by e-mail, your data will be stored for six months for the purpose of processing the request and in the event of follow-up questions. We will not pass on this data without your consent. ',
        ],
      },
      dataStorage: {
        heading: 'Data Storage',
        text: [
          'We would like to point out that for the purpose of our website and for subsequent contract processing, the website operator stores the IP data of the connection holder in the context of cookies.',
          'Our web servers have weblogs, which collects the following data, and stores it for 14 days:',
        ],
        list: [
          'Requested website URL',
          'Broswer information (including browser version, browser type)',
          'Operating system of the website user',
          'Referrer URL (the website from which the website user came to our site)',
          'Hostname of website user',
          'IP address of the website user',
          'Date',
          'Time',
        ],
        text1: [
          'In addition, the following data is also stored with us for the purpose of contract execution:',
        ],
        list1: [
          'Email address',
          'Name (ev. company name)',
          'Address (ev. company address)',
          'Credit card data',
        ],
        text2: [
          'If you sign up on userdocks with your email address, we will store the following data by submitting the fomular:',
        ],
        list2: ['Email address', 'language (primary browser language)'],
        text3: [
          'If you sign up on userdocks via Facebook, Twitter, Github, or Google we will store the following data by submitting the fomular:',
        ],
        list3: [
          'Id (your Id from Facebook/Twitter/Github/Google)',
          'Email Address (your email address used on Facebook/Twitter/Github/Google)',
          'language (primary browser language)',
        ],
        text4: [
          'If you register on Userdocks via Facebook, Twitter, Github, or Google, the access token (access code) for Facebook, Twitter, Github, or Google will not be stored permanently on our servers, i.e. userdocks will only use this access code once to verify your identity , and then discards this code.',
          'userdocks will never post / change anything on Facebook, Twitter, Github, or Google on behalf of your account.',
          'userdocks only uses access to your account to save the associated email address and ID.',
        ],
        text5: [
          'If you connect your stripe account to a userdocks app we will store the following data:',
        ],
        list4: ['stripe account id'],
        text6: [
          'You can provide userdocks with additional personal information.',
          'Some applications on userdocks may allow to create a more detailed profile which can but not necessarily includes the following optional data:',
        ],
        list5: [
          'salutation',
          'other salutation',
          'name',
          'given name',
          'family name',
          'middle name',
          'nickname',
          'preferred username',
          'profile',
          'picture',
          'website',
          'gender',
          'birthdate',
          'zoneinfo',
          'locale',
          'phone number',
        ],
        text7: [
          'If you subscripe to a stripe plan via an userdocks app, we will store the following data:',
        ],
        list6: [
          'stripe customer id',
          'stripe price id',
          'stripe product id',
          'stripe checkout session id',
          'stripe payment method',
          'failed payment',
        ],
        text8: [
          'The data you provide is required to fulfill the contract or to carry out pre-contractual measures. Without this data, we cannot conclude the contract with you. A data transfer to third parties does not take place, with the exception of the transfer of credit card data, email address, first name, last name and address. The credit card data, name, email address and address is sent to the processing bank / payment service provider for the purpose of debiting the purchase price, to the transport company / shipping company commissioned by us to deliver the goods and to our tax advisor to fulfill our tax obligations. The email address, first name, last name, address are passed on to an email marketing service, this service helps us to send our newsletter. The website operator uses the Email Marketing Service MailChimp to send the newsletter.',
          'Furthermore, we would like to point out that at no time is credit card data transmitted to our servers or stored on our servers. The website operator uses the third-party provider Stripe to process all payments made by the website user. All credit card details entered on the website are only sent directly to Stripe via an encrypted (HTTPS) connection. We only have an ID on our server with which we can identify the data on Stripe. On Stripe, the website operator has no way to see the complete credit card number. The website operator only be able to see the last 4 digits and is required to fulfill the contract or to carry out pre-contractual measures.',
          'After termination of the contract, or by deleting your account, the data stored by us will be deleted. In the event of a contract being concluded, all data from the contractual relationship will be stored until the tax retention period expires (7 years).',
          'The data name, address, purchased goods and date of purchase are also stored until product liability expires (10 years).',
          'The data processing takes place on the basis of the legal provisions of § 96 Paragraph 3 TKG as well as Art 6 Paragraph 1 lit a (consent) and / or lit b (necessary to fulfill the contract) of the GDPR.',
        ],
      },
      cookies: {
        heading: 'Cookies',
        text: [
          'Our website uses so-called cookies. These are small text files that are stored on your device using the browser. They do no harm.',
          'We use cookies to make our offer user-friendly. Some cookies remain on your device until you delete them. They enable us to recognize your browser the next time you visit us.',
          'If you do not want this, you can set up your browser so that it informs you about the setting of cookies and you only allow this in individual cases.',
          'If cookies are deactivated, the functionality of our website may be restricted.',
          'userdocks utilizes necessary cookies. Necessary cookies are functional cookies, without this cookies our website, our webapp, and our service would not work.',
          'userdocks uses the following necessary cookies:',
        ],
        list: [
          '"_cfduid": (Provider: https://cloudflare.com) has the purpose of recognizing the user in order to always serve the user via the same routing service, this has the purpose of ensuring the user the best possible optimization of the network usage for the user (session cookie) for a period of a few seconds up to 24 hours.',
          '"_cflb": (Provider: https://cloudflare.com) has the purpose of recognizing the user in order to automatically block possible attackers (session cookie) for a period of 30 days.',
          '"_cf_bm": (Provider: https://cloudflare.com) has the purpose of recognizing the user in order to automatically recognize possible bots (session cookie) for a period of up to 30 minutes.',
          '"userdocks.com:<UUID>:refresh_token": (Provider: https://userdocks.com) has the purpose of recognizing the user in order to automatically log the user in (session cookie) for a period of up to 180 days.',
          '"userdocks.com:<UUID>:session_only": (Provider: https://userdocks.com) has the purpose of recognizing the user in order to know whether the user would like to be recognized again after closing the browser or not (session cookie) for a period until the user closes the browser.',
        ],
      },
      newsletterMailChimp: {
        heading: 'Newsletter - MailChimp',
        text: [
          'For the implementation of all newsletters, the website operator uses the email marketing service MailChimp, the company "The Rocket Science Group, LLC, 675 Ponce de Leon Ave NE, Suite 5000, Atlanta, GA 30308 USA" and the information / information generated about it, that is needed to send the newsletter (e.g. email address, first name, last name) are sent to a data center outside the European Union.',
          "MailChimp uses so-called web beacons (small hidden images within the emails), which enable their users to analyze the use of the newsletter. The information generated in this way is transmitted to the provider's servers (MailChimp).",
          'The data processing takes place on the basis of the legal provisions of § 96 Paragraph 3 TKG as well as Art 6 Paragraph 1 lit a (consent) and / or f (legitimate interest) of the GDPR.',
          'The relationship with the web analytics provider (MailChimp) is based on standard contractual clauses / an adequacy decision of the European Commission, the "Privacy Shield".',
          'You have the option of subscribing to our newsletter via our website. For this we need your email address and your declaration that you agree to receive the newsletter.',
          'In order to provide you with targeted information, we also collect and process voluntarily provided information on areas of interest, birthday.',
          'As soon as you have registered for the newsletter, we will send you a confirmation email with a link to confirm the registration.',
          'You can cancel your subscription to the newsletter at any time. Simply click on the link at the end of each newsletter email or send your cancellation to the following email address: hello@userdocks.at. We will then immediately delete your data in connection with sending the newsletter. This revocation does not affect the legality of the processing carried out based on the consent until the revocation.',
        ],
      },
      paymentStripe: {
        heading: 'Payment - Stripe',
        text: [
          'For all payments on this website, from the website user, to the website operator, we use the third-party provider Stripe, the company "Stripe, Inc., 510 Townsend Street, San Francisco, CA 94103, USA" (or a processing bank and the customer is asked to transfer the payments directly to the bank account of the website operator).',
          'For the purpose of contract processing, the following data is also stored with us:',
        ],
        list: [
          'Email address',
          'Name (ev. company name)',
          'Address (ev. company address)',
          'Credit card data',
        ],
        text1: [
          'The data you provide is required to fulfill the contract or to carry out pre-contractual measures. Without this data, we cannot conclude the contract with you. A data transfer to third parties does not take place, with the exception of the transfer of credit card data, name, email and address - to the processing bank / payment service provider (Stripe) for the purpose of debiting the purchase price, to the transport company / shipping company commissioned by us to deliver the goods and to our tax consultant to fulfill our tax obligations.',
          'We would like to point out that credit card data are never transmitted to our servers or stored on our servers. To process all payments made by the website user, the website operator uses the third-party provider Stripe (or a processing bank and the website user is requested to transfer the payment directly to the bank account of the website operator). All credit card details entered on the website are only sent directly to Stripe via an encrypted (HTTPS) connection. We only have an ID on our server with which we can identify the data on Stripe. On Stripe, the website operator cannot see full credit card numbers, only the last 4 digits. The data you provide is required to fulfill the contract or to carry out pre-contractual measures. ',
          'After termination of the contract or by deleting your account, the data stored by us will be deleted. In the event of a contract being concluded, all data from the contractual relationship will be stored until the tax retention period (7 years) has expired. ',
          'The data name, address, purchased goods and date of purchase will also be saved until the end of product liability (10 years).',
          'The data processing takes place on the basis of the legal provisions of § 96 Paragraph 3 TKG and Art 6 Paragraph 1 lit a (consent) and / or lit b (necessary to fulfill the contract) of the GDPR.',
        ],
      },
      yourRights: {
        heading: 'Your Rights',
        text: [
          `You have the right to information, correction, deletion, restriction, data portability, revocation and objection to your data stored with us. If you believe that the processing of your data violates data protection law or your data protection claims have otherwise been violated, you can complain to us at: ${contactEmail} or the data protection authority.`,
        ],
      },
      contact: {
        heading: 'You can reach us using the following contact details:',
        text: [
          'userdocks',
          'Seidlgasse 7/9',
          '1030 Vienna',
          'Austria',
          'Phone: +43 (0)677/630 520 38',
          `Email: ${contactEmail}`,
        ],
      },
      date: {
        text: [
          'Updated: 19.09.2020',
          'Created with the template of WKO (https://www.wko.at/service/wirtschaftsrecht-gewerberecht/muster-informationspflichten-website-datenschutzerklaerung.html)',
        ],
      },
    },
  },
};

export default i18n;
