import React, { FunctionComponent } from 'react';
import { useI18n } from 'react-i18n-kit';

import { Link, RouterLink } from '../../components/Link';
import Helmet from '../../components/Helmet';
import Container from '../../components/Container';
import { StyledCard, StyledCardBody } from '../../styles';
import Text from '../../components/Text';
import data from './i18nImprintPage';
import { useLanguage } from '../../utils/language';

export interface I18n {
  i18n: typeof data['de'];
}

const ImprintPage: FunctionComponent = () => {
  const { language } = useLanguage();
  const { i18n }: I18n = useI18n(data, {
    lang: language,
  });

  return (
    <Container mt="calc(90px + 1rem)" slim>
      <StyledCard>
        <StyledCardBody>
          <Helmet page="imprint" />

          <Text useType="h1">{i18n.imprint.heading}</Text>

          <Text>{i18n.imprint.company}</Text>

          <Text>{i18n.imprint.person}</Text>

          <Text>
            {i18n.imprint.address.street} {i18n.imprint.address.housenumber}
            <br />
            {i18n.imprint.address.zipCode} {i18n.imprint.address.city}
            <br />
            {i18n.imprint.address.country}
            <br />
          </Text>

          <Text>
            {i18n.imprint.vat.key} {i18n.imprint.vat.value}
          </Text>

          <Text>{i18n.imprint.chamberOfCommerce}</Text>

          <Text>
            {i18n.imprint.jurisdiction.key} {i18n.imprint.jurisdiction.value}
          </Text>

          <Text>
            {i18n.imprint.copyright} {i18n.imprint.copyrightBy.name}
          </Text>

          <Text>
            {i18n.imprint.copyrightBy.text}{' '}
            {i18n.imprint.copyrightBy.hrefs.map((href, i) => {
              if (i + 1 === i18n.imprint.copyrightBy.hrefs.length) {
                return (
                  <>
                    <Link
                      key={`imprint.copyrightBy.hrefs-${href}-${i}`}
                      noMargin
                      href={href}
                    >
                      {href}
                    </Link>
                    {'.'}
                  </>
                );
              }

              return (
                <>
                  <Link
                    key={`imprint.copyrightBy.hrefs-${href}-${i}`}
                    noMargin
                    href={href}
                  >
                    {href}
                  </Link>
                  {', '}
                </>
              );
            })}
          </Text>

          <Text useType="h2">{i18n.privacyPolicy.heading}</Text>

          <Text>
            {i18n.privacyPolicy.text[0]}
            <RouterLink to={i18n.privacyPolicy.link.url}>
              {i18n.privacyPolicy.link.text}
            </RouterLink>
            {i18n.privacyPolicy.text[1]}
          </Text>

          <Text useType="h2">{i18n.terms.heading}</Text>

          <Text>
            {i18n.terms.text[0]}
            <RouterLink to={i18n.terms.link.url}>
              {i18n.terms.link.text}
            </RouterLink>
            {i18n.terms.text[1]}
          </Text>
        </StyledCardBody>
      </StyledCard>
    </Container>
  );
};

export default ImprintPage;
