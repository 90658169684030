const i18n = {
  de: {
    features: {
      features: [
        {
          heading: 'Alle Features',
          text:
            'Egal welchen Plan Sie verwenden. Sie können immer auf alle Features von userdocks zugreifen. Jetzt und in Zukunft.',
        },
        {
          heading: 'Email Login',
          text: 'Registriere neue Nutzer mit Email und Passwort.',
        },
        {
          heading: 'Social Login',
          text:
            'Registriere neue Nutzer über Google, Facebook, Twitter, Github.',
        },
        {
          heading: 'Login Only Apps',
          text:
            'Apps wo man sich nicht registrieren kann. Nutzer müssen eingeladen werden.',
        },
        {
          heading: 'User Rollen',
          text:
            'Erstelle und vergebe unlimitiert User Rollen um Nutzern Teile Ihrer App freizugeben.',
        },
        {
          heading: 'Account-Linking',
          text:
            'Wir erstellen keine doppelten Accounts. Alle Social Logins sind einem Profil zugewiesen.',
        },
        {
          heading: 'Userverwaltung',
          text:
            'Verwalten Sie alle Nutzer direkt über das userdocks Dashboard. Sperre oder Lösche Nutzer.',
        },
        {
          heading: 'API',
          text:
            'Alle Funktionen vom Dashboard sind auch über eine REST API verfügbar.',
        },
        {
          heading: 'Zahlungen',
          text:
            'userdocks intigriert stripe um schnell ein Zahlungssystem zu eurer App hinzuzufügen.',
        },
      ],
      buttons: [
        {
          text: 'Dokumentation',
          title: 'Lesen Sie die userdocks Dokumentation',
        },
        {
          text: 'Kostenlos testen',
          title: 'Jetzt userdocks kostenlos testen',
        },
      ],
    },
  },
  en: {
    features: {
      features: [
        {
          heading: 'All features',
          text:
            'No matter what plan you use. You can always access all features of userdocks. Now and in future.',
        },
        {
          heading: 'Email sign in',
          text: 'Register new users with email and password.',
        },
        {
          heading: 'Social sign in',
          text: 'Register new users via Google, Facebook, Twitter, and Github.',
        },
        {
          heading: 'Invite only apps',
          text:
            'Create applications where users can not register. New users can only be invited.',
        },
        {
          heading: 'User roles',
          text:
            'Create and assign unlimited user roles to give users acces to parts of your app.',
        },
        {
          heading: 'Account linking',
          text:
            'We do not create duplicate accounts. All social logins are linked to one profile.',
        },
        {
          heading: 'User management',
          text:
            'Manage all users directly on the userdocks dashboard. Block or delete users.',
        },
        {
          heading: 'API',
          text:
            'All functions from the dashboard are also available via our REST API.',
        },
        {
          heading: 'Payments',
          text:
            'userdocks integrates stripe to quickly add a payment system to your app.',
        },
      ],
      buttons: [
        {
          text: 'Read the docs',
          title: 'Read the userdocks documentation',
        },
        {
          text: 'Try for free',
          title: 'Try userdocks for free',
        },
      ],
    },
  },
};

export default i18n;
