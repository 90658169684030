import { styled, css } from '../../theme';
import Container from '../Container';

export const StyledHeader = styled.div<{
  isSmall?: boolean;
  boxShadow?: string;
}>`
  background: ${({ theme }) => theme.colors.white};
  height: 60px;
  transition: padding 0.4s;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  box-sizing: content-box;

  ${({ isSmall }) =>
    !isSmall &&
    css`
      padding: 1rem 0;
    `}
  ${({ isSmall, boxShadow }) =>
    isSmall &&
    css`
      ${({ theme }) => theme.snippets.boxShadow(boxShadow)};
    `}

  ${Container} {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }

  img {
    height: 60px;
    object-fit: contain;
    padding: 0.6rem 0;
    margin-right: 0.5rem;
    left: 0;
    top: 0;
  }
`;

export const StyledMobileMenu = styled.div<{ isSmall?: boolean }>`
  background: white;
  height: 100vh;
  position: fixed;
  z-index: 99;
  width: 100vw;
  top: ${({ isSmall }) => (isSmall ? '60px' : '90px')};
`;

export const StyledLinks = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;

  li {
    padding: 0 1rem;
  }
`;
