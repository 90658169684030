import { domainName } from './domainName';
import { contactEmail } from './contactEmail';

export const protocol = 'https://';
export const url = `${protocol}${domainName}`;
export const mailToLinkContact = `mailto:${contactEmail}`;
export const baseAPILink = `${protocol}${process.env.SERVER_NAME_API}`;
export const baseLandingLink = `${protocol}${process.env.SERVER_NAME_LANDING}`;
export const baseLoginLink = `${protocol}${process.env.SERVER_NAME_LOGIN}`;
export const baseAppLink = `${protocol}${process.env.SERVER_NAME_APP}/legacy`;
export const baseDocsLink = 'https://docs.userdocks.com';

export const privacyPolicyUrl = (lang) =>
  lang === 'de'
    ? `${baseLandingLink}/de/datenschutz`
    : `${baseLandingLink}/en/privacy`;

export const termsAndConditionsUrl = (lang) =>
  lang === 'de' ? `${baseLandingLink}/de/agb` : `${baseLandingLink}/en/terms`;

export const pricingUrl = (lang) =>
  lang === 'de'
    ? `${baseLandingLink}/de/preise`
    : `${baseLandingLink}/en/pricing`;

export const apiRoutes = {
  redirect_uri: `${baseAppLink}/callback`,
  redirectUriThirdPartyToIdentity: (app, thirdParty) =>
    `${baseLoginLink}/oauth/${thirdParty}/identity/${app.id}/callback`,
  signInOrUpEmail: `${baseAPILink}/rest/pc/v1/login/oauth/email/sign_in_or_up`,
  signInOrUpGithub: `${baseAPILink}/rest/pc/v1/login/oauth/github/sign_in_or_up`,
  signInOrUpGoogle: `${baseAPILink}/rest/pc/v1/login/oauth/google/sign_in_or_up`,
  signInOrUpTwitter: `${baseAPILink}/rest/pc/v1/login/oauth/twitter/sign_in_or_up`,
  signInOrUpFacebook: `${baseAPILink}/rest/pc/v1/login/oauth/facebook/sign_in_or_up`,
  connectStripe: `${baseAPILink}/rest/pc/v1/oauth/stripe/connect`,
  connectMailchimp: `${baseAPILink}/rest/pc/v1/oauth/mailchimp/connect`,
  confirm: `${baseAPILink}/rest/pc/v1/confirm`,
  forgotPassword: `${baseAPILink}/rest/pc/v1/forgot-password`,
  resetPassword: `${baseAPILink}/rest/pc/v1/reset-password`,
  refresh: `${baseAPILink}/rest/pc/v1/refresh`,
  logout: `${baseAPILink}/rest/pc/v1/logout`,
  redirect: `${baseAPILink}/rest/pt/login/oauth/github/sign_in_or_up`,
};
